import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IMaterialSearchResult, SearchService, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { ROUTES } from '../routes';
import { AssessmentCategory, Material } from '../../entities/EntityModels';
import { MaterialListItem } from '../../entities/projections/MaterialListItem';

import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'safety-assessment',
    templateUrl: './safety-assessment.html',
})

export class SafetyAssessmentComponent implements OnInit {
    _editing = false;
    _material: Material;
    _materialListItems: MaterialListItem[];
    _assessmentCategories: AssessmentCategory[];
    _pageState = new PageState('Safety Assessment');

    _documentManagementUrl: string;
    _isLoading = false;
    _validationMessage: string;

    constructor(public _stateMap: StateMap, public _userManager: UserManager, public _searchService: SearchService, public _router: Router,
                public _uow: UnitOfWork, private _httpClient: HttpClient) {
    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.SafetyAssessment.name;
        this._material = this._stateMap.currentMaterial;
        this._pageState.isLoaded = this.hasAssessment();
        this.getRelatedMaterials(this._material.materialId);
        this._uow.fetch('Materials/MaterialAndAdditionalMaterialAssessmentCategories', {materialId: this._material.materialId})
            .then(r => {
                this._assessmentCategories = r;
            });

        // needed for file downloads
        this.getDocumentManagementUrl().then(url => {
            this._documentManagementUrl = url + '/RIFMDownloadableFile';
        });
    }

    hasAssessment() {
        const assessment = this._material.assessmentReportName;
        return (assessment != null) && (assessment.trim().length > 0);
    }

    hasElsevierAssessment() {
        const assessment = this._material.elsevierReportName;
        return (assessment != null) && (assessment.trim().length > 0);
    }

    downloadReport() {
        this.downloadSAFile(this._material.assessmentReportName, 'SafetyAssessments');
    }

    downloadElsevierReport() {
        this.downloadSAFile(this._material.elsevierReportName, 'Elsevier SAs');
    }

    getRelatedMaterials(materialId: number): Promise<IMaterialSearchResult> {
        this._materialListItems = null;
        const params = {
            searchTerm: '' + materialId,
            searchType: 'safety-assessment-materials',
            pageNumber: 1,
            pageSize: 500  // insure that we bring them all in.
        };

        return this._searchService.fetchMaterialsPage(params).then(r => {
            const mlItems = r.materials.filter(m => m.materialId != this._material.materialId);
            mlItems.forEach(m => {
                m['assessmentReportDate'] = m.assessmentReportNameModified && new Date(m.assessmentReportNameModified);
            });
            this._materialListItems = mlItems;
            return r;
        });
    }

    selectMaterial(material: MaterialListItem) {
        return this._router.navigate(UtilFns.asRouterParts(ROUTES.Material, material.materialId));
    }

    // ***********************************************
    // *sa & elsevier document downloads
    // ***********************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    getDownloadFileURL(fileName: string, folderName: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + folderName ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public downloadSAFile(fileName: string, folderName: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, folderName)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = '';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}
