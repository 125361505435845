import { Location } from '@angular/common';
import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {UnitOfWork, StateMap, UserManager, ErrorLogger, WorkflowEntityState, ReferenceState} from '../../services/common';
import { UtilFns } from '../../utils/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { Reference, RIFMDocument, RIFMDocumentFolder, RIFMDocumentArchive, TypeRIFMDocument } from '../../entities/EntityModels';
import { FileHandle } from '../../entities/projections/FileHandle';

import {Observable} from 'rxjs';

import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';
import { ROUTES } from '../routes';

import {ReferenceDocumentListItem} from '../../entities/projections/ReferenceDocumentListItem';

export class UploadFileData {
    fileName: string;
    fileIndex: number;
    referenceId: number;
    hasRIFMDocumentRecord: boolean;
    rIFMDocumentId: number;
    results: string;
    successAdd: boolean;
    successReplace: boolean;
    ignore: boolean;
}

@Component({
    selector: 'quick-document-upload',
    templateUrl: '../docmanagement/quick-document-upload.html',
})
export class QuickDocumentUploadComponent implements OnInit, AfterViewInit {

    public _files: any = [];
    public _referenceDocumentListItems: ReferenceDocumentListItem[];
    public _workflowReference: Reference;

    // Default type ids
    public _typeRIFMDocumentId: string;

    public _isLoading = false;
    public _isProcessingFiles = false;

    public _fileInvalidList: { fileName: string, errorMsg: string }[] = [];
    public _fileValidList: UploadFileData[] = [];
    public _fileInputElement: any;
    public _readyForMore = false;
    public _validationMessage: string;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                private _httpClient: HttpClient, private _referenceState: ReferenceState,
                private _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {
    }

    ngOnInit() {
        this._stateMap.currentRouteName = DOCUMENTMANAGEMENT_ROUTES.QuickDocumentUpload.name;

        if (!this.isValidString(this._stateMap.documentManagementUrl)) {
            this.getDocumentManagementUrl().then(url => {
                this._stateMap.documentManagementUrl = url + '/RIFMDocument';
            });
        }
    }

    ngAfterViewInit() {
        this._fileInputElement = $('#fileInputElement')[0] as HTMLInputElement;
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public isValidString(testString: string): boolean {
        return (testString && testString.length > 0);
    }

    // ************************************************************************
    // load data
    // ************************************************************************
    fetchRIFMDocuments(referenceIds: number[]): Promise<any> {
        this._validationMessage = '';

        return this._uow.fetch('DocumentManagement/FetchRIFMDocumentsByReferenceId', {referenceIds: referenceIds}).then(f => {
            if (f != null && f.length > 0) {
                this._referenceDocumentListItems = f;
            }
        });
    }

    // ************************************************************************
    canDeactivate() {
        return !this._isProcessingFiles;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get utilFns() {
        return UtilFns;
    }

    // ************************************************************************
    // file data
    // ************************************************************************
    public get fileCount() {
        if (this._files == null || this._files.length == 0) {
            return '';
        }

        return ('There are ' + this._files.length + ' file(s) ready to upload.');
    }

    public parseFileName(fileName: string): number {
        try {
            const filePart = fileName.substring(0, fileName.lastIndexOf('.'));
            const refId = parseInt(filePart, 10);
            return (!isNaN(refId)) ? refId : 0;
        } catch (error) {
            console.log(error);
            return 0;
        }
    }

    // ************************************************************************
    // file interactions
    // ************************************************************************
    allowDrop(event: any) {
        event.preventDefault();
    }

    onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    onDrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();

        this._files = evt.dataTransfer.files;
    }

    onFileInput(files: FileList) {
        if (files == null || files.length == 0) {
            return;
        }
        this._files = files;
    }

    onAddDocument(ix: number) {
        this._isLoading = true;
        this.addReferenceDocument(ix);

        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
        this._isLoading = false;
    }

    onReplaceFile(ix: number) {
        this._isLoading = true;
        this.replaceReferenceDocumentFile(ix);

        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
        this._isLoading = false;
    }

    onRemoveFileFromList(ix: number) {
        this._fileValidList[ix].ignore = true;
    }

    async onUploadAll() {
        this._isLoading         = true;
        this._isProcessingFiles = true;

        const newFiles = _.clone(this._fileValidList);

        // upload file data
        for (let idx = 0; idx < newFiles.length; idx++) {
            if (newFiles[idx].ignore == false) {
                if (newFiles[idx].hasRIFMDocumentRecord) {
                    const result = await this.replaceReferenceDocumentFile(idx);
                } else {
                    const result = await this.addReferenceDocument(idx);
                }
            }
        }

        if (this._uow.hasChanges()) {
            this._uow.commit();
        }

        this._readyForMore = true;
        this._files = [];

        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }

        this._isLoading             = false;
        this._isProcessingFiles     = false;
    }

    clearData() {
        this._readyForMore = false;
        this._validationMessage = '';

        this._files = [];
        this._fileInvalidList = [];
        this._fileValidList = [];
        this._referenceDocumentListItems = [];

        if (this._fileInputElement != null) {
            this._fileInputElement.value = null;
        }
    }

    // ************************************************************************
    // page interaction
    // ************************************************************************
    canCancel() {
        return (this._files != null && this._files.length > 0);
    }

    canDragFiles() {
        return (this._fileValidList == null || this._fileValidList.length == 0);
    }

    canPrepareFiles() {
        if (this._fileValidList != null && this._fileValidList.length > 0) {
            return false;
        }

        return (this._files != null && this._files.length > 0);
    }

    canUploadAll() {
        if (this._readyForMore) {
            return false;
        }

        return (this._fileValidList != null && this._fileValidList.length > 0);
    }

    onAddMore() {
        this.clearData();
    }

    onCancel() {
        this.clearData();
    }

    public onPrepareFiles() {
        this._fileInvalidList = [];
        this._fileValidList = [];

        // sort files by name
        this._files = _.sortBy(this._files, n => n.name);

        for (let idx = 0; idx < this._files.length; idx++) {
            const refId = this.parseFileName(this._files[idx].name);

            if (refId == 0) {
                this._fileInvalidList.push({fileName: this._files[idx].name, errorMsg: 'Invalid file name: cannot identify the Reference Id.'});
            } else {
                // tslint:disable-next-line:max-line-length
                this._fileValidList.push({
                    fileName: this._files[idx].name,
                    fileIndex: idx,
                    referenceId: refId,
                    hasRIFMDocumentRecord: false,
                    rIFMDocumentId: 0,
                    results: '',
                    successAdd: false,
                    successReplace: false,
                    ignore: false
                });
            }
        }

        if (this._fileValidList.length == 0) {
            return;
        }

        const refIds = this._fileValidList.map(i => i.referenceId);

        // match rifm document to file reference ids
        this.fetchRIFMDocuments(refIds).then(r => {
            if (this._referenceDocumentListItems == null || this._referenceDocumentListItems.length == 0) {
                return;
            }

            for (let idx = 0; idx < this._fileValidList.length; idx++) {
                const rifmdoc = this._referenceDocumentListItems.filter(d => d.referenceId == this._fileValidList[idx].referenceId);
                if (rifmdoc != null && rifmdoc.length > 0) {
                    this._fileValidList[idx].hasRIFMDocumentRecord = true;
                    this._fileValidList[idx].rIFMDocumentId = rifmdoc[0].rIFMDocumentId;
                }
            }
        });
    }

    get fileValidList(): UploadFileData[] {
        if (this._fileValidList == null) {
            return [];
        }
        return this._fileValidList.filter(f => f.ignore == false);
    }

    public onNavToRefDoc(idx: number) {
        const fileData = this._fileValidList[idx];
        this._stateMap.documentManagementReferenceId = fileData.referenceId;
        this._router.navigate(UtilFns.asRouterParts(ROUTES.DocumentManagement, ROUTES.DocumentManagement.childRoutes.RIFMDocuments));
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getAddDocumentURL(fileData: UploadFileData): Observable<any> {
        const url = this._stateMap.documentManagementUrl + '/AddRIFMDocument';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', fileData.referenceId + '');
        formData.append('fileName', fileData.referenceId + '.pdf');
        formData.append('file', this._files[fileData.fileIndex]);

        return this._httpClient.post<any>(url, formData);
    }

    getReplaceDocumentURL(fileData: UploadFileData): Observable<any> {
        const url = this._stateMap.documentManagementUrl + '/ReplaceRIFMDocument';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', fileData.referenceId + '');
        formData.append('rifmDocumentId', fileData.rIFMDocumentId + '');
        formData.append('fileName', fileData.referenceId + '.pdf');
        formData.append('file', this._files[fileData.fileIndex]);

        return this._httpClient.post<any>(url, formData);
    }

    public addReferenceDocument(idx: number): Promise<any> {

        const fileData = this._fileValidList[idx];

        const url       = this._stateMap.documentManagementUrl + '/AddRIFMDocument';
        const headers   = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', fileData.referenceId + '');
        formData.append('fileName', fileData.referenceId + '.pdf');
        formData.append('file', this._files[fileData.fileIndex]);

        return this._httpClient.post<any>(url, formData)
            .toPromise()
            .then(response => {
                if (response) {
                    const data = response;

                    if (data.Success == false) {
                        this._fileValidList[idx].successAdd = false;
                        this._fileValidList[idx].results    = 'Error: ' + data.ExceptionMessage;
                        return false;
                    }

                    this._fileValidList[idx].successAdd = true;
                    this._fileValidList[idx].results    = 'Added Successfully';

                    return true;
                }
                return false;
            })
            .catch(() => {
                this._fileValidList[idx].successAdd = false;
                this._fileValidList[idx].results    = 'Error Occurred';
                return false;
            });

    }

    public replaceReferenceDocumentFile(idx: number): Promise<any> {
        const fileData = this._fileValidList[idx];

        const url = this._stateMap.documentManagementUrl + '/ReplaceRIFMDocument';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('referenceId', fileData.referenceId + '');
        formData.append('rifmDocumentId', fileData.rIFMDocumentId + '');
        formData.append('fileName', fileData.referenceId + '.pdf');
        formData.append('file', this._files[fileData.fileIndex]);

        return this._httpClient.post<any>(url, formData)
            .toPromise()
            .then(response => {
                if (response) {
                    const data = response;

                    if (data.Success == false) {
                        this._fileValidList[idx].successReplace = false;
                        this._fileValidList[idx].results        = 'Error: ' + data.ExceptionMessage;
                        return false;
                    }
                    this._fileValidList[idx].successReplace = true;
                    this._fileValidList[idx].results        = 'Replaced Successfully';

                    return true;
                }
                return false;
            })
            .catch(() => {
                this._fileValidList[idx].successReplace = false;
                this._fileValidList[idx].results        = 'Error Occurred';
                return false;
            });
    }
}
