<div>
    <p class="bold page-title">Document Management Reports</p>
</div>

<div class="flex-container" style="width: 90%;">
    <div class="flex-item">
        <span class="page_subtitle bold">Select Report: </span>
    </div>
    <div class="flex-item">
        <select class="form-control" [ngModel]="_selectedReportIndex" (change)="onSelectReport($event.target.value)">
            <option *ngFor="let r of _referenceDocumentReports; let ix = index" [value]="ix">{{r.name}}</option>
        </select>
    </div>
    <div class="flex-item">
        <p>{{selectedReportDescription}}</p>
    </div>
    <div *ngIf="_missingReferenceDocuments != null && _missingReferenceDocuments.length" class="flex-item">
        <button type="button" class="inline btn btn-download" style="width: 130px;"
                (click)="exportMissingDocumentCSVFile()">Export to File</button>
    </div>
</div>
<div class="row">
    <i *ngIf="_isLoading" class="fa fa-circle-o-notch fa-spin" style="font-size:38px; color: #337ab7;"></i>
</div>
<div class="missing-documents-container">
    <div *ngIf="_missingReferenceDocuments != null && _missingReferenceDocuments.length" style="border-bottom: 1px solid #424242; width: 1430px;">
        <div>
            <div class="scrollableTableHeader">
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">Ref Id</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 130px">Brief Reference</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 320px">Title</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">Reference Created Date</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 140px">Reference Created User</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 70px">Captive Paper</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 160px">File Name</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">Most Recent Workflow Activity</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">Most Recent Workflow Date</span></div>
                <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">Date Reference Document Created</span></div>
            </div>
            <div style="height: 240px; overflow-y:auto;overflow-x: hidden;">
                <div class="scrollableTableRow" *ngFor="let rd of _missingReferenceDocuments let ix=index" [ngStyle]="{ 'background-color': rowColor(ix) }">
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 60px">{{rd.referenceId}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 130px">{{rd.briefReference}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 320px">{{rd.title}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{rd.created}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 140px">{{rd.createdUser}}</span></div>
                    <div class="scrollableTableColumn text-left"><span class="inline" style="width: 70px">{{rd.captive}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 160px">{{rd.fileName}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{rd.lastWorkflowActivityId}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{rd.formattedWorkflowActivityDate}}</span></div>
                    <div class="scrollableTableColumn text-center"><span class="inline" style="width: 80px">{{rd.formattedReferenceDocumentCreateDate}}</span></div>
                </div>
            </div>

        </div>
    </div>
</div>
