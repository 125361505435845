import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { provideParent, ReferenceState, StateMap, UnitOfWork } from '../../services/common';
import { UtilFns } from '../../utils/common';
import { EditPropParent } from '../../controls/common';

import {
    BiologicalData,
    ExperimentalMaterial,
    ExperimentalResult,
    ExperimentalToxicEffect,
    Material,
    TypeExperimentVehicle,
    TypeStudyMixture,
    TypeToxicEffect,
    TypeUnit
} from '../../entities/EntityModels';
import { MaterialSelectorComponent } from '../material/material-selector.component';

@Component({
    selector: 'exp-material-editor',
    templateUrl: './exp-material-editor.html',
    providers: [provideParent(ExpMaterialEditorComponent, EditPropParent)]
})
export class ExpMaterialEditorComponent implements OnInit, EditPropParent {
    @ViewChild(MaterialSelectorComponent, { static: true }) _materialSelectorComponent: MaterialSelectorComponent;

    @Input() expMaterial: ExperimentalMaterial;

    public compOps: { compOp: string; desc: string }[] = [
        { compOp: '0', desc: 'None' },
        { compOp: '=', desc: '=' },
        { compOp: '>', desc: '>' },
        { compOp: '>=', desc: '>=' },
        { compOp: '<', desc: '<' },
        { compOp: '<=', desc: '<=' },
    ];

    _bd: BiologicalData;
    _material: Material;
    _typeUnits: TypeUnit[];
    _typeToxicEffects: TypeToxicEffect[];
    _typeToxicEffectIdCandidate = '';

    _calculatedToxicEffects: TypeToxicEffect[];
    _typeExperimentVehicles: TypeExperimentVehicle[];
    _isAddingVehicle: boolean;
    _addedVehicleCode: string;
    _addedVehicleDescription: string;
    _defaultProps: { compOp: string, typeUnitId: string };

    _typeStudyMixtures: TypeStudyMixture[];

    expandProtocol: boolean;
    expandExpMaterial: boolean;
    expandExpResults: boolean;

    _sortedExperimentalResults: ExperimentalResult[];

    // StateMap is used in html
    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, private _referenceState: ReferenceState) {
    }

    ngOnInit() {
        // keep track of any deleted subentities.
        this._bd = this.expMaterial.biologicalData;
        this._uow.typeUnitRepository.all().then(r => this._typeUnits = r);
        this._uow.typeToxicEffectRepository.all().then(r => {
            this._typeToxicEffects = _.sortBy(r, function (t) { return t.sortDescription.toLowerCase(); });
            this._calculatedToxicEffects = r.filter(tte => tte.calculated);
        });
        this._uow.typeExperimentVehicleRepository.all().then(r => {
            this._typeExperimentVehicles = _.sortBy(r, tev => tev.vehicle);
        });
        this._uow.typeStudyMixtureRepository.all().then(r => this._typeStudyMixtures = r);
        this.expandProtocol = false;
        this.expandExpMaterial = !!this.expMaterial.material;
        this.expandExpResults = true;

        if (this.expMaterial.experimentalResults != null) {
            this._sortedExperimentalResults = _.sortBy(this.expMaterial.experimentalResults, e => e.dose);
        }
    }

    getError(propName: string, id: string) {
        if (id != null) {
            const ix = parseInt(id, 10);
            if (ix != null) {
                return this.expMaterial.experimentalResults[ix].getErrorFor(propName);
            }
        } else {
            return this.expMaterial.getErrorFor(propName);
        }

    }

    onAddVehicle() {
        this._isAddingVehicle = true;
    }

    canAcceptVehicle() {
        // check if valid lengths.
        return this._addedVehicleCode && (this._addedVehicleCode.length > 0 && this._addedVehicleCode.length <= 8)
            && this._addedVehicleDescription && (this._addedVehicleDescription.length > 0 && this._addedVehicleDescription.length <= 50)
            && this._typeExperimentVehicles.find(v => v.typeExperimentVehicleId == this._addedVehicleCode) == null;
    }

    onAcceptVehicle() {
        const p = {
            typeExperimentVehicleId: this._addedVehicleCode,
            vehicle: this._addedVehicleDescription
        };
        try {
            const expVehicle = this._uow.typeExperimentVehicleFactory.create(p);
            this._typeExperimentVehicles.push(expVehicle);
            this.expMaterial.typeExperimentVehicleId = this._addedVehicleCode;
            this._addedVehicleCode = null;
            this._addedVehicleDescription = null;
            this._isAddingVehicle = false;
        } catch (e) {

        }

    }

    onCancelVehicle() {
        this._isAddingVehicle = false;
    }

    parseVehicle(v: string) {
        if (v == 'undefined' || v == '') {
            return null;
        } else {
            return v;
        }
    }

    onSelectMaterial() {
        UtilFns.showModal(this._materialSelectorComponent, this).then(mli => {
            if (mli == null) {
                return;
            }
            return this._uow.materialRepository.withId(mli.materialId);
        }).then(m => {
            if (m) {
                this.expMaterial.material = m;
                this.expandExpMaterial = true;
            }
        });
    }

    onAddExperimentalResult() {

        this.getPreviousDoseAndUnitType();

        const params = {
            experimentalMaterialId: this.expMaterial.experimentalMaterialId,
            compOp: this._defaultProps.compOp,
            typeUnitId: this._defaultProps.typeUnitId,
            typeExperimentVehicleId: 'NONE'
        };
        const expResult = this._uow.experimentalResultFactory.create(params);
        // Default typetoxiceffect to 'no effects'
        this.onAddExperimentalToxicEffect(expResult, 'NOE');
        this.expandExpResults = true;
        // TODO: set focus on the expResult just added.
    }

    onDeleteExperimentalResult(er: ExperimentalResult) {
        if (er.entityAspect.entityState.isAdded() == false) {
            this._referenceState.recordToxDataDelete(this._stateMap.currentReference);
        }

        this.expMaterial.addDeletedChild(er);
        er.cascadeDelete();
    }

    onAddExperimentalToxicEffect(er: ExperimentalResult, typeToxicEffectId: string) {
        if (typeToxicEffectId == '') {
            return;
        }
        // clear the field for next use.

        setTimeout(() => {
            this._typeToxicEffectIdCandidate = '';
        }, 0);
        if (!typeToxicEffectId) {
            return;
        }
        if (er.experimentalToxicEffects.find(ete => ete.typeToxicEffectId == typeToxicEffectId)) {
            return;
        }
        const params = {
            experimentalResultId: er.experimentalResultId,
            typeToxicEffectId: typeToxicEffectId
        };
        const expToxicEffect = this._uow.experimentalToxicEffectFactory.create(params);

    }

    onDeleteExperimentalToxicEffect(ete: ExperimentalToxicEffect) {
        this.expMaterial.addDeletedChild(ete);
        ete.cascadeDelete();

    }

    onCancel() {
        this._uow.typeExperimentVehicleRepository.all().then(r => {
            let tevs = r.filter(tev => {
                const ok = tev.entityAspect.entityState.isUnchanged();
                if ((!ok) && (!tev.entityAspect.entityState.isDetached())) {
                    tev.entityAspect.rejectChanges();
                }

                return ok;
            });
            tevs = _.sortBy(tevs, tev => tev.vehicle);
            tevs.unshift(new TypeExperimentVehicle());
            this._typeExperimentVehicles = tevs;
        });
    }

    // Null out empty strings
    onSetCalculatedResult(val: string) {
        if (val != null && val === '') {
            this.expMaterial.calculatedResult = null;
        }
    }

    onSetTypeToxicEffectId(val: string) {
        if (val != null && val === '') {
            this.expMaterial.typeToxicEffectId = null;
        } else {
            this.expMaterial.typeToxicEffectId = val;
        }
    }

    // Default a new Experimental Result CompOp and Unit Type to the previous (if any) Experimental Result's values for a given Experimental Material.
    // Addresses a Usability Issue when entering many results.
    private getPreviousDoseAndUnitType() {

        if (this.expMaterial && this.expMaterial.experimentalResults) {
            if (this.expMaterial.experimentalResults.length > 0) {
                const lastexpres = this.expMaterial.experimentalResults[this.expMaterial.experimentalResults.length - 1];
                this._defaultProps = { compOp: lastexpres.compOp, typeUnitId: lastexpres.typeUnitId };
                return;
            }
        }

        this._defaultProps = { compOp: '=', typeUnitId: 'NONE' };

        return;
    }
}
