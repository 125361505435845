import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ReferenceFinishState, ReferenceState, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, IEditHost, UtilFns } from '../../utils/common';

import { AnalyticalResultComponent } from './analytical-result.component';
import { AnalyticalResult, Material, Reference, WorkflowMaterialRecordedAction} from '../../entities/EntityModels';
import { MaterialWorkflowService } from '../../services/material-workflow-service';
import { WorkflowEntityState } from '../../services/workflow-state';

import { ROUTES } from '../routes';
import { MATERIAL_ROUTES } from './material.routes';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import * as _ from 'lodash';

export enum UVFileAction { NONE, ADD, DELETE }

export class UVFileData {
    addCount: number;
    uvFiles: { key: number, fileName: string, processType: UVFileAction, processResultMsg: string, analyticalResultId: number, fileData: any } [];
}

export class ReferenceLink {
    public _referenceId: number;
    public _hasAddition: boolean;
    public _hasDeletion: boolean;

    constructor(referenceId?: number, hasAddition?: boolean, hasDeletion?: boolean) {
        this._referenceId = referenceId || null;
        this._hasAddition = hasAddition || false;
        this._hasDeletion = hasDeletion || false;
    }
}

@Component({
    selector: 'analytical-results',
    templateUrl: './analytical-results.html',
    providers: [MaterialWorkflowService],
})
export class AnalyticalResultsComponent implements IEditHost<AnalyticalResult>, OnInit, AfterViewInit {
    @ViewChild(AnalyticalResultComponent) _editingComponent: AnalyticalResultComponent;

    _material: Material;
    _edm: EditManager<AnalyticalResult>;
    _edmWorkflowReferences: EditManager<Reference>;
    _workflowLinkedReferences: ReferenceLink[] = [];
    _materialWorkflowService: MaterialWorkflowService;
    _finishedMessage: string;
    _uvsFileSavedMessage: string;
    _isLoading = false;
    _unfinishedRefs: string;
    _uvsFileURL: string;
    _validationMessage: string;

    public _data: UVFileData = new UVFileData();
    public _uvsAddedSuccessfully = false;

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _location: Location, public _router: Router, private _userManager: UserManager,
                private _referenceState: ReferenceState, private changeDetectorRef: ChangeDetectorRef, private _httpClient: HttpClient, materialWorkflowService: MaterialWorkflowService) {
        this._materialWorkflowService = materialWorkflowService;
    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.AnalyticalResults.name;
        this._edm = new EditManager<AnalyticalResult>(this, this._uow, this._stateMap, 'Physical Data');
        this._material = this._stateMap.currentMaterial;

        this.fetch();

        // Reference Workflow
        this._edmWorkflowReferences = new EditManager<Reference>(this, this._uow, this._stateMap, 'Workflow References');
        this._edmWorkflowReferences.entities = [];

        // needed for UVS file management
        this.getDocumentManagementUrl().then(url => {
            this._uvsFileURL = url + '/AnalyticalResults';
        });

        this.resetUVData();
    }

    fetch() {
        const p = {materialId: this._material.materialId};

        this._isLoading = true;

        return this._uow.analyticalResultRepository.whereWithParams(p).then(r => {
            const result = UtilFns.sort(r, true,
                (x: AnalyticalResult) => (x.typeAnalyticalProcedure && x.typeAnalyticalProcedure.analyticalProcedure) || '');
            this._edm.entities = result;
            this._edm.pageState.isLoaded = result;
            this._isLoading = false;
        }).catch(e => {
            this._edm.pageState.isLoaded = [];
            this._isLoading = false;
        });
    }

    ngAfterViewInit() {
        this.changeDetectorRef.detectChanges();
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    selectAnalyticalResult(ar: AnalyticalResult) {
        this._edm.onSelect(ar);
    }

    getEntities() {
        if (this.isStaff()) {
            return this._edm.entities;
        } else {
            return this._edm.entities.filter(a => a.reference == null || a.reference.complete);
        }
    }

    get analyticalResults() {
        if (this.isStaff()) {
            return this._edm.entities;
        } else {
            return this._edm.entities.filter(a => a.reference == null || a.reference.complete);
        }
    }

    hasAnalyticalResults(): boolean {
        return (this._edm.entities != null && this._edm.entities.length > 0);
    }

    navToRef(ar: AnalyticalResult) {
        this._router.navigate(UtilFns.asRouterParts(ROUTES.Reference, ar.reference.referenceId));
    }

    stopProp(event: Event) {
        event.stopPropagation();
    }

    formatAnalyticalSource(ar: AnalyticalResult) {
        try {
            return Reference.formatBriefReference(ar.analyticalSource, this.isStaff());
        } catch (e) {
            return ar.analyticalSource;
        }
    }

    private downloadReport(fileName: string, event: any) {
        const url = this._userManager.getUVUrl(fileName);
        window.open(url);
        event.stopPropagation();
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onApply() {
        // HACK
        const r = this._edm.onApplyCore();
        const x = this._edm.entities;
        this._edm.entities = null;
        setTimeout(() => this._edm.entities = x, 0);
        return r;
    }

    onAdd() {
        if (this._edm.editing && this._editingComponent) {
            if (!this._edm.validateCurrent()) {
                return;
            }
        }

        if (this._data.uvFiles != null && this._data.uvFiles.length > 0) {
            const addedFiles = this._data.uvFiles.filter(u => u.processType == UVFileAction.ADD);
            if (addedFiles) {
                this._data.addCount = addedFiles.length;
            }
        }

        const entity = this._uow.analyticalResultFactory.create();
        entity.materialID = this._material.materialId;
        this._edm.entities.push(entity);
        this._edm.onSelect(entity);
    }

    onSave() {
        this._isLoading = true;
        this._validationMessage = '';

        // right now only for UVS file activity
        this.recordMaterialWorkflowActivity();

        return this.recordWorkflowChanges().then(s => {
            this._edmWorkflowReferences.onSaveCore()
                .then(async () => {
                    this._edm.onSaveCore();

                    if (this._unfinishedRefs) {
                        this._finishedMessage = 'These References are now marked Unfinished and need checked: ' + this._unfinishedRefs;
                    }

                    // process UVS file requests
                    if (this.hasUVFileChanges()) {
                        this._uvsFileSavedMessage = '';
                        let result: any;
                        for (let idx = 0; idx < this._data.uvFiles.length; idx++) {
                            const processType = this._data.uvFiles[idx].processType;

                            switch (processType) {
                                case UVFileAction.ADD: {
                                    result = await this.addUVFile(idx);
                                    break;
                                }
                                case UVFileAction.DELETE: {
                                    result = await this.removeUVFile(this._data.uvFiles[idx].fileName);
                                    break;
                                }
                                default: {
                                    break;
                                }

                                    this._data.uvFiles[idx].processResultMsg = result;

                            }
                        }

                        this._uvsFileSavedMessage = this._data.uvFiles.filter(m => m.processResultMsg).map(m => m.processResultMsg).join(', ');

                        this.resetUVData();

                    }

                    // refresh Material physical properties
                    return this.fetch();

                    this._isLoading = false;
                });
        });
    }

    // Record deletions of linked references in workflow
    onDelete() {
        const analyticalResultId = this._edm.currentEntity.analyticalResultId;

        if (this._edm.currentEntity.reference != null) {

            if (this._edmWorkflowReferences.entities == null) {
                this._edmWorkflowReferences.entities = [];
            }

            this.fetchDeletedLinkedReference(this._edm.currentEntity.reference.referenceId).then(r => {

                if (this.indexOfEditReference(r.referenceId, this._edmWorkflowReferences.entities) == -1) {
                    this._edmWorkflowReferences.entities.push(r);
                    this._workflowLinkedReferences.push(new ReferenceLink(r.referenceId, false, true));
                } else { // If it exists in the _edmWorkflowReferences then it should already be in the _workflowLinkedReferences array
                    const idx = this.indexOfReferenceLink(r.referenceId, this._workflowLinkedReferences);
                    this._workflowLinkedReferences[idx]._hasDeletion = true;
                }

            });
        }

        this._edm.onDeleteCore();

        // mark all assigned uv files for deletion on save and record deletions in the material workflow history
        const files = this._data.uvFiles.filter(f => f.analyticalResultId == analyticalResultId && f.processType != UVFileAction.ADD);
        if (files != null && files.length > 0) {
            files.forEach(f => {
                f.processType = UVFileAction.DELETE;
            });
        }
    }

    // This will include the related WorkflowRecordedAction records
    private fetchDeletedLinkedReference(referenceId: number): Promise<Reference> {
        return this._uow.referenceRepository.withId(referenceId);
    }

    onCancel() {
        if (this._edm.editing) {
            const analyticalResultId = this._edm.currentEntity.analyticalResultId;
            const currentFileData = _.clone(this._data.uvFiles);
            this._data.uvFiles = currentFileData.splice(currentFileData.findIndex(a => a.analyticalResultId === analyticalResultId), 1);
        }

        this._edmWorkflowReferences.entities = [];
        this._workflowLinkedReferences = [];

        this._edm.onCancelCore();

        this.resetUVData();
    }

    private recordWorkflowChanges(): Promise<any> {
        this._finishedMessage = '';
        this._unfinishedRefs = '';

        this.markWorkflowAdds();

        const func = this.processWorkflowItem.bind(this);

        const promises = this._workflowLinkedReferences.map(function (reflink) {
            return func(reflink);
        });

        return Promise.all(promises);
    }

    private markWorkflowAdds() {

        const changedEntities = this._edm.entities;
        if (changedEntities == null || changedEntities.length == 0) {
            return Promise.resolve(true);
        }

        if (this._edmWorkflowReferences.entities == null) {
            this._edmWorkflowReferences.entities = [];
        }

        changedEntities.forEach(e => {
            if (e.entityAspect.entityState.isAdded() || e.entityAspect.entityState.isModified()) {
                if (e.reference != null) {
                    if (this.indexOfEditReference(e.referenceId, this._edmWorkflowReferences.entities) == -1) {
                        this._edmWorkflowReferences.entities.push(e.reference);
                        this._workflowLinkedReferences.push(new ReferenceLink(e.referenceId, true, false));
                    } else { // If it exists in the _edmWorkflowReferences then it should already be in the _workflowLinkedReferences array
                        const idx = this.indexOfReferenceLink(e.referenceId, this._workflowLinkedReferences);
                        this._workflowLinkedReferences[idx]._hasAddition = true;
                    }
                }
            }
        });
    }

    public processWorkflowItem(reflink: ReferenceLink): Promise<any> {

        const idx = this.indexOfEditReference(reflink._referenceId, this._edmWorkflowReferences.entities);

        if (reflink._hasAddition) {
            this._referenceState.recordAnalyticalResultAdd(this._edmWorkflowReferences.entities[idx], this._material.materialId);
        }

        if (reflink._hasDeletion) {
            this._referenceState.recordAnalyticalResultDelete(this._edmWorkflowReferences.entities[idx], this._material.materialId);
            this._referenceState
                .reconcileLinkedAnalyticalResultDeletesWithPendingActions(this._edmWorkflowReferences.entities[idx], this._edmWorkflowReferences.entities[idx].analyticalResults);
        }

        // AnalyticalResult records can be linked to a Reference for UV Spectra Observations.
        // Adding or Deleting linked References can affect the Finished state of the record.
        return this.checkWorkflowItemFinishedState(idx);
    }

    private checkWorkflowItemFinishedState(idx: number): Promise<any> {
        return this._referenceState.isReferenceFinished(this._edmWorkflowReferences.entities[idx]).then(f => {
            const finished: boolean = (f == ReferenceFinishState.MissingData) ? false : true;

            if (finished != this._edmWorkflowReferences.entities[idx].finished) {
                this._edmWorkflowReferences.entities[idx].finished = finished;
                this._referenceState.recordReferenceFinishedStateChange(this._edmWorkflowReferences.entities[idx]);
                if (this._unfinishedRefs) {
                    this._unfinishedRefs = ', ' + this._edmWorkflowReferences.entities[idx].referenceId.toString();
                } else {
                    this._unfinishedRefs = this._edmWorkflowReferences.entities[idx].referenceId.toString();
                }
            }
        });
    }

    private indexOfReferenceLink(referenceId: number, data: ReferenceLink[]): number {
        if (data == null || data.length == 0) {
            return -1;
        }

        let idx = data.length;
        while (idx--) {
            if (data[idx]._referenceId === referenceId) {
                return idx;
            }
        }
        return -1;
    }

    private indexOfEditReference(referenceId: number, data: Reference[]): number {
        if (data == null || data.length == 0) {
            return -1;
        }

        let idx = data.length;
        while (idx--) {
            if (data[idx].referenceId === referenceId) {
                return idx;
            }
        }
        return -1;
    }

    // UVS file links
    public downloadUVSFile(fileName: string) {
        if (fileName) {
            this.exportUVSFile(fileName);
        }
    }

    public referenceFinishedStatesHaveChanged(): boolean {
        return (this._finishedMessage && this._finishedMessage.length > 0);
    }

    // uva and uvs file logic
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public showUVFileMessage() {
        return (!this._edm.editing &&  this._edm.hasEdits() && this.hasUVFileChanges() );
    }

    // ************************************************************************
    // uv file related methods
    // ************************************************************************
    hasUVFileChanges() {
        if (this._data.uvFiles == null || this._data.uvFiles.length == 0) {
            return false;
        }
        const files = this._data.uvFiles.filter(f => f.processType != UVFileAction.NONE);
        return (files != null && files.length > 0);
    }

    recordMaterialWorkflowActivity() {
        if (this.hasUVFileChanges()) {
            for (let idx = 0; idx < this._data.uvFiles.length; idx++) {
                const processType = this._data.uvFiles[idx].processType;

                switch (processType) {
                    case UVFileAction.ADD: {
                        // tslint:disable-next-line:max-line-length
                        this._materialWorkflowService.recordMaterialUVSFileAdded(this._material.materialId, WorkflowEntityState.Added, this._data.uvFiles[idx].fileName, this._data.uvFiles[idx].analyticalResultId, this._userManager.currentUser.name);
                        break;
                    }
                    case UVFileAction.DELETE: {
                        // tslint:disable-next-line:max-line-length
                        this._materialWorkflowService.recordMaterialUVSFileDeleted(this._material.materialId, WorkflowEntityState.Deleted, this._data.uvFiles[idx].fileName, this._data.uvFiles[idx].analyticalResultId, this._userManager.currentUser.name);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
    }

    resetUVData() {
        this._data.addCount         = 0;
        this._data.uvFiles          = [];
        this._validationMessage     = '';
    }

    // ************************************************************************
    // web api calls
    // ************************************************************************
    getAddUVDocumentURL(fileIdx: number): Observable<any> {
        const url = this._uvsFileURL + '/UploadUVFile';
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        const formData: FormData = new FormData();
        formData.append('materialId', this._edm.currentEntity.materialID + '');
        formData.append('fileName', this._data.uvFiles[fileIdx].fileName);
        formData.append('file', this._data.uvFiles[fileIdx].fileData);

        return this._httpClient.post<any>(url, formData);
    }

    public addUVFile(fileIdx: number): Observable<string> {
        const subject = new Subject<string>();
        this.getAddUVDocumentURL(fileIdx)
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        subject.next('Error occurred: ' + data.ExceptionMessage);
                    } else {
                        subject.next('File was processed successfully.');
                    }
                },
                (error) => {
                    subject.next(error.message);
                });

        return subject.asObservable();
    }

    getRemoveUVFileURL(fileName: string): Observable<any> {
        const url = this._uvsFileURL + '/Remove?fileName=' + fileName;

        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this._httpClient.post<any>(url, { });
    }

    public removeUVFile(fileName: string): Observable<string> {
        const subject = new Subject<string>();
        this._isLoading = true;

        this.getRemoveUVFileURL(fileName)
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._uvsFileSavedMessage = 'Error occurred, unable to remove the file: ' + data.ExceptionMessage;
                        subject.next('Error occurred, unable to remove the file: ' + data.ExceptionMessage);
                    }

                    this._uvsFileSavedMessage = 'UV File archived and removed successfully.';
                    subject.next('UV File archived and removed successfully.');
                },
                (error) => {
                    subject.next(error.message);
                },
                () => {
                    this._isLoading = false;
                });

                return subject.asObservable();
    }

    getDownloadFileURL(fileName: string): Observable<any> {
        const url = this._uvsFileURL + '/GetUVFileByName?fileName=' + fileName;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public exportUVSFile(fileName: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}
