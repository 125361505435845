import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { EditorService, StateMap, UnitOfWork, UserManager } from '../../services/common';
import { EditManager, LocationFns, UtilFns } from '../../utils/common';

import { MATERIAL_ROUTES } from './material.routes';
import { RegulatoryStatusComponent } from './regulatory-status.component';
import { FoodStatus, RegulatoryStatus } from '../../entities/EntityModels';

import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
    selector: 'flavor-statuses',
    templateUrl: './flavor-statuses.html',
})
export class FlavorStatusesComponent implements OnInit {
    @ViewChild(RegulatoryStatusComponent) editingComponent: RegulatoryStatusComponent;

    @Input() materialId: number;


    _registeredFlavors: FoodStatus[] = []; // unique subset of FoodStatues
    _foodStatuses: FoodStatus[]; // all food statuses
    _flavorStatuses: (FoodStatus | RegulatoryStatus)[] = []; // combo used for display.
    _edm: EditManager<RegulatoryStatus>;
    _documentManagementUrl: string;
    _isLoading = false;
    _validationMessage: string;

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _editorService: EditorService, public _location: Location, private _httpClient: HttpClient) {
    }

    ngOnInit() {
        LocationFns.setTab(this._location, 0);

        this._stateMap.currentRouteName = MATERIAL_ROUTES.Flavor.name;
        this._edm = this._editorService.createEditManager<RegulatoryStatus>(this, 'Flavor Status');
        const material = this._stateMap.currentMaterial;
        const p = {materialId: this.materialId};
        this._edm.pageState.isLoading = true;

        this._uow.foodStatusRepository.whereWithParams(p).then(r => {
            this._registeredFlavors = r.filter(fs => fs.regulatoryTypeForCompare().toLowerCase() == 'identifier' && (fs.foodAgencyId == 'Flavis' || fs.foodAgencyId == 'JECFA'));
             // JECFA can be applied for non-identifing regulatory status
            this._foodStatuses = r.filter(fs => fs.regulatoryTypeForCompare().toLowerCase() != 'identifier' && fs.foodAgencyId != 'Flavis');
            this._flavorStatuses = this._flavorStatuses.concat(this._foodStatuses);
            this._flavorStatuses = _.sortBy(this._flavorStatuses, fs => fs.evaluatingAgency());
            this._edm.pageState.isLoaded = r;
        });

        const p2 = {materialId: this.materialId, statusType: 'flavor'};
        this._uow.regulatoryStatusRepository.whereWithParams(p2).then(r => {
            this._edm.entities = r;
            this._flavorStatuses = this._flavorStatuses.concat(r);
            this._flavorStatuses = _.sortBy(this._flavorStatuses, fs => fs.evaluatingAgency());
        });

        // needed for UVS file management
        this.getDocumentManagementUrl().then(url => {
            this._documentManagementUrl = url + '/RIFMDownloadableFile';
        });
    }

    canDeactivate() {
        return !this._edm.hasEdits();
    }

    onSelect(e: FoodStatus | RegulatoryStatus) {
        if (this.isRegulatoryStatus(e)) {
            this._edm.onSelect(<RegulatoryStatus>e);
        }
    }

    selectWebRef(e: FoodStatus | RegulatoryStatus) {

        const filePath = e.webReferenceExt();

        if (!this.isValidString(filePath)) {
            return false;
        }

        if (filePath.indexOf('http') == 0) {
            const url = filePath;
            if (url) {
                window.open(url, '_blank');
                return false;
            }
        }

        const [fileName, subFolder] = this.parseFilePath(filePath);

        this.downloadFoodStatusFile(fileName, subFolder);
    }

    isRegulatoryStatus(fs: RegulatoryStatus | FoodStatus) {
        return UtilFns.getClassName(fs) == 'RegulatoryStatus';
    }

    // EditManager overrides

    onAdd() {
        if (!this._edm.validateCurrent()) {
            return;
        }
        const rs = this._uow.regulatoryStatusFactory.create();
        rs.materialId = this.materialId;
        this._flavorStatuses.push(rs);
        this._edm.entities.push(rs);
        this.onSelect(rs);
    }

    onDelete() {
        const e = this._edm.currentEntity;
        this._edm.onDeleteCore();
        _.remove(this._flavorStatuses, e);
        this._edm.pageState.isLoaded = this._flavorStatuses;
    }

    onApply() {
        this._edm.onApplyCore();
        this._edm.pageState.isLoaded = this._flavorStatuses;
    }

    onCancel() {
        this._edm.onCancelCore();
        _.remove(this._flavorStatuses, fs => fs.entityAspect.entityState.isDetached());
        this._edm.pageState.isLoaded = this._flavorStatuses;
    }


    onSelectRegulatoryStatus(evt: any) {
        this.onSelect(evt.selectedRowsData[0]);
        return false;
    }

    isValidString(teststring: string) {
        if (!teststring) { return false; }

        return (teststring.trim().length > 0);
    }

    // ***********************************************
    // *document downloads
    // ***********************************************
    public parseFilePath(filepath: string) {
        if (!this.isValidString(filepath)) { return ['', '']; }

        // replace forward slashes with backslashes
        const newpath = filepath.split('/').join('\\');

        const tempSubFolder = newpath.substring(0, newpath.lastIndexOf('\\'));
        const fileName = newpath.substring(newpath.lastIndexOf('\\') + 1);

        // Remove any backslashes at the beginning of the subfolder(s)
        const subFolder = tempSubFolder.replace(/^\\+/, '');

        console.log('parsed filepath: ' + subFolder + ', ' + fileName);
        return [fileName, subFolder];
    }

    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    getDownloadFileURL(fileName: string, subFolder: string): Observable<any> {
        let url: string;
        url = this._documentManagementUrl + '/GetFileBySubFolderAndName?fileName=' + fileName + '&subFolder=' + subFolder ;

        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    public downloadFoodStatusFile(fileName: string, subFolder: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getDownloadFileURL(fileName, subFolder)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = fileName;
                    document.body.appendChild(a);
                    a.click();

                    this._validationMessage = 'File download successful.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }
}
