import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { IRouteDefinition, StateMap, UnitOfWork, UserManager } from '../../services/common';

import { Reference, DocUniqReference } from '../../entities/EntityModels';
import { ReferenceResolver } from './reference.resolver';
import { REFERENCE_ROUTES } from './reference.routes';
import { ShowAndDownloadPDFComponent } from '../docmanagement/show-and-download-pdf.component';
import {UtilFns} from '../../utils/util-fns';

@Component({
    selector: 'reference',
    templateUrl: './reference.html',
})
export class ReferenceComponent implements OnInit, OnDestroy {
    @ViewChild(ShowAndDownloadPDFComponent, { static: true }) _showAndDownloadPDFComponent: ShowAndDownloadPDFComponent;

    reference: Reference;
    _referenceNotFound: boolean;

    _hasReferenceDocumentFile = false;
    _referenceFileName = '';

    _sub: any;
    _routes = <IRouteDefinition[]>_.values(REFERENCE_ROUTES);

    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap,
                public _referenceResolver: ReferenceResolver, public _location: Location, public _route: ActivatedRoute) {

    }

    ngOnInit() {
        this._sub = this._route.params.subscribe(params => {
            // this is needed to get the right header to display
            const referenceId = +params['id'];
            this._referenceResolver.fetch(referenceId).then(m => {
                this.reference = m;
                this._referenceNotFound = !this.reference;
                this.fetchReferenceDocumentFileStatus();
            });
        });
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    get utilFns() {
        return UtilFns;
    }

    isActiveTab(r: IRouteDefinition) {
        const currentRouteName = this._stateMap.currentRouteName || this._routes[0].name;
        return r.name == currentRouteName;
    }

    canDeactivate() {
        if (this._uow.hasChanges()) {
            return this._stateMap.yesNoComponent.showModal(
                'Changes will be lost', 'Do you want to continue?').then(ok => {
                if (ok) {
                    this._uow.rollback();
                }
                return ok;
            });
        } else {
            return Promise.resolve(true);
        }
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    fetchReferenceDocumentFileStatus() {
        this._hasReferenceDocumentFile = false;

        if (this.reference == null) {
            return false;
        }

        return this._uow.fetch('DocumentManagement/RIFMDocumentByReferenceId', {referenceId: this.reference.referenceId}).then(rd => {
            const doc = (rd && rd.length) ? rd[0] : null;
            if (doc != null) {
                this._referenceFileName         = doc.fileName;
                this._hasReferenceDocumentFile  =  (doc.fileName != null && doc.fileName.length > 0);
            }
        });
    }

    canDownloadReferenceDocument(): boolean {

        if (this.isStaff() || this._userManager.currentUser.isInRole('fema')) {
            return true;
        }

        if (this.reference) {
            if (this._userManager.currentUser.isInRole('iff') && (this.matchDocumentToRole('iff') || this.matchDocumentToRole('bba'))) {
                return true;
            }
        }

        return false;
    }

    matchDocumentToRole(role: string) {
        if (this.reference.briefReference.length < role.length) {
            return false;
        }

        return (this.reference.briefReference.substring(0, role.length).toUpperCase() == role.toUpperCase());
    }

    showReferenceDocument() {
        this._showAndDownloadPDFComponent.fileName = this._referenceFileName;
        UtilFns.showModal(this._showAndDownloadPDFComponent, this).then(m => {
            return;
        });
    }
}
