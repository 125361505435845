import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { StateMap, UnitOfWork } from '../../services/common';
import { Material } from '../../entities/Material';
import { Observable, from } from 'rxjs';

@Injectable()
export class MaterialResolver implements Resolve<Material> {
    private _url: string;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this._url = this._router.url;
        const id = parseInt(route.params['id'], 10);
        return from(this.fetch(id));
    }

    fetch(materialId: number): Promise<Material> {
        const cm = this._stateMap.currentMaterial;
        if (cm && cm.materialId == materialId) {
            return Promise.resolve(cm);
        }
        this._uow.clearEntityManager();
        return this._uow.materialRepository.withId(materialId).then(m => {
            if (!m) {
                const p = this._stateMap.yesNoComponent.showModal('Material not available',
                    'This material does exist but is not currently available.', ['Ok']);
                return p.then(() => {
                    // so we return to our original location.
                    this._router.navigateByUrl(this._url);
                    return m;
                });
            }
            this._stateMap.currentMaterial = m;
            this.fetchHasDataInfo(m); // fetch info about which tabs have data - lazily.
            return m;
        });

    }

    fetchHasDataInfo(material: Material) {

        const materialId = material.materialId;
        // determine which tabs have data

        // StructureIdentity - naturalOccurrences tab
        const p = {materialId: materialId};
        this._uow.naturalOccurrenceDescriptorRepository.whereWithParams(p).then(r => {
            this._stateMap.setHasMaterialDataFor('natural-occurrences', r.length > 0);
        });

        // Relations tab and subtabs.
        this._uow.fetch('Materials/MaterialRelationsBundle', p).then(r => {
            const obj = <any>r[0];
            this._stateMap.setHasMaterialDataFor('relations-materials', (obj.items.length > 0) || (obj.naturalItems.length > 0));
        }).then(() => {
            return this._uow.materialFSAGRepository.whereWithParams({materialId: materialId, sagId: null}).then(r => {
                this._stateMap.setHasMaterialDataFor('relations-groups', r.length > 0);
            });
        }).then(() => {
            const hasRelationsData = this._stateMap.hasMaterialDataFor('relations-materials')
                || this._stateMap.hasMaterialDataFor('relations-groups');
            this._stateMap.setHasMaterialDataFor('relations', hasRelationsData);
        });

        // Flavor tabs and subtabs
        let rfs: any;
        this._uow.foodStatusRepository.whereWithParams(p).then(r => {
            rfs = r;
            const pFlavor = {materialId: materialId, statusType: 'flavor'};
            return this._uow.regulatoryStatusRepository.whereWithParams(pFlavor);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('flavor-statuses', rfs.length > 0 || r.length > 0);

            const pFlavor = {materialId: materialId};
            return this._uow.materialUsesRepository.whereWithParams(pFlavor);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('flavor-uses', r.length > 0);

            const pFlavor = {materialId: materialId, materialType: 'flavor'};
            return this._uow.materialConsumptionRepository.whereWithParams(pFlavor);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('flavor-volumes', r.length > 0);

            const hasFlavorsData = this._stateMap.hasMaterialDataFor('flavor-statuses')
                || this._stateMap.hasMaterialDataFor('flavor-uses')
                || this._stateMap.hasMaterialDataFor('flavor-volumes');
            this._stateMap.setHasMaterialDataFor('flavor', hasFlavorsData);
        });

        // Status tabs and subtabs

        this._uow.nationalToxicologyProgramRepository.whereWithParams(p).then(r => {
            this._stateMap.setHasMaterialDataFor('status-ntps', r.length > 0);

            const pStatus = {materialId: materialId, statusType: 'general'};
            return this._uow.regulatoryStatusRepository.whereWithParams(pStatus);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('regulatory-statuses-general', r.length > 0);

            const pStatus = {materialId: materialId, statusType: 'ifra'};
            return this._uow.regulatoryStatusRepository.whereWithParams(pStatus);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('regulatory-statuses-ifra', r.length > 0);

            const pStatus = {materialId: materialId, statusType: 'occupational'};
            return this._uow.regulatoryStatusRepository.whereWithParams(pStatus);
        }).then(r => {
            this._stateMap.setHasMaterialDataFor('regulatory-statuses-occupational', r.length > 0);

            const hasStatusData = this._stateMap.hasMaterialDataFor('status-ntps')
                || this._stateMap.hasMaterialDataFor('regulatory-statuses-general')
                || this._stateMap.hasMaterialDataFor('regulatory-statuses-ifra')
                || this._stateMap.hasMaterialDataFor('regulatory-statuses-occupational');
            this._stateMap.setHasMaterialDataFor('status', hasStatusData);
        });

        // Predictions / Cramer class
        this._uow.predictionRepository.whereWithParams(p).then(r => {
            this._stateMap.setHasMaterialDataFor('prediction-hh-cramer', r.length > 0);
        });

        const hasAssessment = (material.assessmentReportName != null) && (material.assessmentReportName.trim().length > 0);
        this._stateMap.setHasMaterialDataFor('safety-assessment', hasAssessment);

        // Publications
        this._uow.materialExpertPanelSummaryRepository.whereWithParams({materialId: materialId}).then(r => {
            const hasFFIDS = (material.fFIDSFile != null) && material.fFIDSFile.trim() !== '';
            const hasMonograph = (material.monographReference != null) && (material.monographReference.trim() !== '');
            const summariesLength = r.map(item => item.expertPanelSummary)
                .filter(s => s.narrativeLink != null && s.narrativeLink.trim() != '').length;
            this._stateMap.setHasMaterialDataFor('publications', summariesLength > 0 || hasFFIDS || hasMonograph);
        });

        // Reach
        this._uow.rEACHRegistrationRepository.whereWithParams({materialId: materialId}).then(r => {
            this._stateMap.setHasMaterialDataFor('reach', r.length > 0);
        });

        // Material Consumption
        this._uow.materialConsumptionRepository.whereWithParams({materialId: materialId}).then(mc => {
            const withuse = mc.filter(k => k.kilogramsConsumed > 0);
            this._stateMap.setHasMaterialDataFor('material-consumptions', withuse.length > 0);
        });

        let hasCompositionData = false;
        let isComponent = false;

        // Material Composition
        this._uow.materialCompositionRepository.whereWithParams({materialId: materialId}).then(mc => {
            hasCompositionData = (mc != null && mc.length > 0);
            this._stateMap.setHasMaterialDataFor('material-composition', hasCompositionData);
            if (hasCompositionData) {
                this._stateMap.setHasMaterialDataFor('naturals', true); // Naturals
            }
        });

        // Material Component
        this._uow.materialComponentRepository.whereWithParams({materialId: materialId}).then(c => {
            isComponent = (c != null && c.length > 0);
            this._stateMap.setHasMaterialDataFor('material-partof-compounds', isComponent);
            if (isComponent) {
                this._stateMap.setHasMaterialDataFor('naturals', true); // Naturals
            }
        });

        // Naturals: only set false once to avoid flicker
        if (hasCompositionData == false && isComponent == false) {
            this._stateMap.setHasMaterialDataFor('naturals', false);
        }

        // Material Exposure Data
        this._uow.materialExposureSurveyRepository.whereWithParams({materialId: materialId}).then(mes => {
            if (mes.length > 0) {
                this._stateMap.setHasMaterialDataFor('material-exposure', mes.length > 0);
            } else {
                this._uow.materialExposureSurveyIsomerRepository.whereWithParams({materialId: materialId}).then(mis => {
                    this._stateMap.setHasMaterialDataFor('material-exposure', mis.length > 0);
                });
            }
        });

    }
}
