import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerLicense } from '@syncfusion/ej2-base';
// import { BreezeBridgeHttpClientModule } from 'breeze-bridge2-angular';

import { appRouterProviders, routes } from './route-provider';

import { SERVICE_PROVIDERS } from './services/service-registration';
import { customExceptionHandlerProvider } from './services/custom-exception-handler-provider';

import { AppComponent } from './components/main/app.component';
import { ExceptionDialogComponent } from './components/main/exception-dialog.component';
import { LoginComponent } from './components/main/login.component';
import { PrintToPdfDocComponent } from './components/main/print-to-pdf-doc.component';
import { ToolBar } from './components/main/toolbar.component';
import { YesNoComponent } from './components/main/yes-no.component';

import { MaterialResolver } from './components/material/material.resolver';
import { ReferenceResolver } from './components/reference/reference.resolver';

import { DefaultTableComponent } from './controls/default-table.component';
import { EditorHeaderComponent } from './controls/editor-header.component';
import { EditPropComponent } from './controls/edit-prop.component';
import { ColumnSorterDirective } from './controls/column-sorter.directive';
import { DatePickerDirective } from './controls/date-picker-dummy.directive';
import { HtmlGreekDirective } from './controls/html-greek.directive';
import { ChemFormulaDirective } from './controls/chem-formula.directive';
import { PageStatusComponent } from './controls/page-status.control';
import { TabContainerVert } from './controls/tab-container/tab-container-vert.component';
import { TabContainer } from './controls/tab-container/tab-container-horz.component';
import { TabPane } from './controls/tab-container/tab-pane.directive';
import { FormatUVFiles } from './controls/format-uvfiles.pipe';
import { PaginatePipe, PaginationControlsComponent } from './controls/ng2-pagination/ng2-pagination';

import { ExposureAdditionalMaterialsComponent } from './components/exposure/exposure-additional-materials.component';
import { ExposureAdditionalMaterialEditorComponent } from './components/exposure/exposure-additional-material-editor.component';
import { ExposureAdditionalMaterialSurveyMeasureEditorComponent } from './components/exposure/exposure-additional-material-survey-measure-editor.component';
import { ExposureAdditionalMaterialSurveyMeasuresComponent } from './components/exposure/exposure-additional-material-survey-measures.component';
import { ExposureComponent } from './components/exposure/exposure.component';
import { ExposureAdditionalMaterialSurveysComponent } from './components/exposure/exposure-additional-material-surveys.component';
import { ExposureAdditionalMaterialSurveyEditorComponent } from './components/exposure/exposure-additional-material-survey-editor.component';
import { ExposureSurveyEditorComponent } from './components/exposure/exposure-survey-editor.component';
import { ExposureSurveyLastReportComponent } from './components/exposure/exposure-survey-last-report.component';
import { ExposureSurveysComponent } from './components/exposure/exposure-surveys.component';
import { ExposureSurveyListsComponent } from './components/exposure/exposure-survey-lists.component';
import { ExposureSurveyReportsComponent } from './components/exposure/exposure-survey-reports.component';
import { MaterialExposureSurveyEditorComponent } from './components/exposure/material-exposure-survey-editor.component';
import { MaterialExposureSurveyIsomerMeasuresComponent } from './components/exposure/material-exposure-survey-isomer-measures.component';
import { MaterialExposureSurveyMeasuresComponent } from './components/exposure/material-exposure-survey-measures.component';
import { MaterialExposureSurveyMeasureEditorComponent } from './components/exposure/material-exposure-survey-measure-editor.component';
import { MaterialExposureSurveysComponent } from './components/exposure/material-exposure-surveys.component';
import { RecordExposureSurveyMeasuresComponent } from './components/exposure/record-exposure-survey-measures.component';

import { ClusterHelpVideoComponent } from './components/home/cluster-help-video.component';
import { ClusterPathFilterComponent } from './components/home/clusterpath-filter.component';
import { ClusterPathSelectorComponent } from './components/home/clusterpath-selector.component';
import { CytoscapeGraphComponent } from './components/home/cytoscape-graph.component';
import { HomePageComponent } from './components/home/home-page.component';
import { HomeComponent } from './components/home/home.component';
import { DatabaseSubscriptionComponent } from './components/home/database-subscription.component';
import { EnvironmentalDataComponent } from './components/home/environmental-data.component';
import { IFRAAttachmentsComponent } from './components/home/ifra-attachments.component';
import { IFRASurveyUseComponent } from './components/home/ifra-survey-use.component';
import { IntroComponent } from './components/home/intro.component';
import { MarvinSearchComponent } from './components/home/marvin-search.component';
import { MaterialClustersComponent } from './components/home/material-clusters.component';
import { MaterialFFIDSComponent } from './components/home/material-ffids.component';
import { MaterialGroupsComponent } from './components/home/material-groups.component';
import { ReportDatabaseChangesComponent } from './components/home/report-database-changes.component';
import { SafetyAssessmentsComponent } from './components/home/safety-assessments.component';
import { SearchAuthorsComponent } from './components/home/search-authors.component';
import { SearchResultsComponent } from './components/home/search-results.component';
import { SearchSynonymsComponent } from './components/home/search-synonyms.component';
import { SystemRequirementsComponent } from './components/home/system-requirements.component';
import { ToolsAndModelComponent } from './components/home/tools-and-model.component';
import { ToolsAndModelsComponent } from './components/home/tools-and-models.component';
import { ToolsAndModelsLicenseComponent } from './components/home/tools-and-models-license.component';
import { UserAcknowledgementComponent } from './components/home/user-acknowledgement.component';
import { WhatsNewComponent } from './components/home/whats-new.component';

import { AnalyticalResultComponent } from './components/material/analytical-result.component';
import { AnalyticalResultsComponent } from './components/material/analytical-results.component';
import { FlavorComponent } from './components/material/flavor.component';
import { FlavorStatusesComponent } from './components/material/flavor-statuses.component';
import { FlavorUsesComponent } from './components/material/flavor-uses.component';
import { FlavorUsesItemComponent } from './components/material/flavor-uses-item.component';
import { FlavorVolumesComponent } from './components/material/flavor-volumes.component';
import { FragranceComponent } from './components/material/fragrance.component';
import { FragranceUseComponent } from './components/material/fragrance-use.component';
import { HouseStatusComponent } from './components/material/house-status.component';
import { HouseStatusesComponent } from './components/material/house-statuses.component';
import { HumanHealthComponent } from './components/material/human-health.component';
import { MaterialAdditionalIdentifiersComponent } from './components/material/material-additional-identifiers.component';
import { MaterialComponent } from './components/material/material.component';
import { MaterialCompositionComponent } from './components/material/material-composition.component';
import { MaterialConsumptionsComponent } from './components/material/material-consumptions.component';
import { MaterialExposureComponent } from './components/material/material-exposure.component';
import { MaterialHeaderComponent } from './components/material/material-header.component';
import { MaterialPartOfCompoundsComponent } from './components/material/material-partof-compounds.component';
import { MaterialResultsComponent } from './components/material/material-results.component';
import { MaterialSelectorComponent } from './components/material/material-selector.component';
import { MaterialSummaryComponent } from './components/material/material-summary.component';
import { NaturalsComponent } from './components/material/naturals.component';
import { NaturalOccurrenceComponent } from './components/material/natural-occurrence.component';
import { NaturalOccurrencesComponent } from './components/material/natural-occurrences.component';
import { PredictionEnvironmentalComponent } from './components/material/prediction-environmental.component';
import { PredictionHHAlertComponent } from './components/material/prediction-hh-alert.component';
import { PredictionHHCramerComponent } from './components/material/prediction-hh-cramer.component';
import { PredictionHHTTCComponent } from './components/material/prediction-hh-ttc.component';
import { PredictionHumanHealthComponent } from './components/material/prediction-human-health.component';
import { PredictionsComponent } from './components/material/predictions.component';
import { PublicationsComponent } from './components/material/publications.component';
import { REACHRegistrationComponent } from './components/material/reach-registration.component';
import { REACHRegistrationsComponent } from './components/material/reach-registrations.component';
import { RegulatoryStatusComponent } from './components/material/regulatory-status.component';
import { RegulatoryStatusesComponent } from './components/material/regulatory-statuses.component';
import { RelationsComponent } from './components/material/relations.component';
import { RelationsGroupComponent } from './components/material/relations-group.component';
import { RelationsGroupsComponent } from './components/material/relations-groups.component';
import { RelationsMaterialComponent } from './components/material/relations-material.component';
import { RelationsMaterialsComponent } from './components/material/relations-materials.component';
import { SafetyAssessmentComponent } from './components/material/safety-assessment.component';
import { StatusNtpComponent } from './components/material/status-ntp.component';
import { StatusNtpsComponent } from './components/material/status-ntps.component';
import { StatusRootComponent } from './components/material/status-root.component';
import { StructureComponent } from './components/material/structure.component';
import { StructureIdentityComponent } from './components/material/structure-identity.component';
import { SynonymComponent } from './components/material/synonym.component';
import { SynonymsComponent } from './components/material/synonyms.component';
import { SynopsisComponent } from './components/material/synopsis.component';
import { SynopsisReportComponent } from './components/material/synopsis-report.component';
import { ToxDataOtherReferencesComponent } from './components/material/tox-data-other.component';
import { ToxicDataComponent } from './components/material/toxic-data.component';

import { AuthorSelectorComponent } from './components/reference/author-selector.component';
import { ReferenceComponent } from './components/reference/reference.component';
import { ReferenceDataComponent } from './components/reference/reference-data.component';
import { ReferenceDataReportComponent } from './components/reference/reference-data-report.component';
import { ReferenceDetailsComponent } from './components/reference/reference-details.component';
import { ReferenceHeaderComponent } from './components/reference/reference-header.component';
import { ReferenceRelationsComponent } from './components/reference/reference-relations.component';
import { ReferenceResultsComponent } from './components/reference/reference-results.component';
import { ReferenceSelectorComponent } from './components/reference/reference-selector.component';

import { AuthorEditorComponent } from './components/staff/author-editor.component';
import { SafetyAssessmentCategoryEditorComponent } from './components/staff/safety-assessment-category-editor.component';
import { ComplianceSelectorComponent } from './components/staff/compliance-selector.component';
import { ConfirmFinishOverrideComponent } from './components/staff/confirm-finish-override.component';
import { ExpMaterialEditorComponent } from './components/staff/exp-material-editor.component';
import { ExpMaterialFromTemplateComponent } from './components/staff/exp-material-from-template.component';
import { FSAGEditorComponent } from './components/staff/fsag-editor.component';
import { FSAGSelectorComponent } from './components/staff/fsag-selector.component';
import { JournalsComponent } from './components/staff/journals.component';
import { JournalEditorComponent } from './components/staff/journal-editor.component';
import { JournalReservesComponent } from './components/staff/journal-reserves.component';
import { JournalReserveEditorComponent } from './components/staff/journal-reserve-editor.component';
import { JournalNamesComponent } from './components/staff/journal-names.component';
import { JournalReassignComponent } from './components/staff/journal-reassign.component';
import { JournalSearchComponent } from './components/staff/journal-search.component';
import { JournalSubscriptionEditorComponent } from './components/staff/journal-subscription-editor.component';
import { JournalSubscriptionsComponent } from './components/staff/journal-subscriptions.component';
import { JournalSelectorComponent } from './components/staff/journal-selector.component';
import { MaterialEditorBaseComponent } from './components/staff/material-editor-base.component';
import { MaterialEditorComponent } from './components/staff/material-editor.component';
import { MaterialReportedUsesComponent } from './components/staff/material-reported-uses.component';
import { MaterialReportedUseEditorComponent } from './components/staff/material-reported-use-editor.component';
import { MaterialWorkflowHistoryComponent } from './components/staff/material-workflow-history.component';
import { PredictionCramerEditorComponent } from './components/staff/prediction-cramer-editor.component';
import { PredictionsCramerEditorComponent } from './components/staff/predictions-cramer-editor.component';
import { ProjectEditorComponent } from './components/staff/project-editor.component';
import { ProjectSelectorComponent } from './components/staff/project-selector.component';
import { ProtocolEditorComponent } from './components/staff/protocol-editor.component';
import { ProtocolEditorBiodataComponent } from './components/staff/protocol-editor-biodata.component';
import { ProtocolEditorSubStudiesComponent } from './components/staff/protocol-editor-substudies.component';
import { ProtocolMaterialSelectorComponent } from './components/staff/protocol-material-selector.component';
import { ReferenceAnalyticalResultsComponent } from './components/staff/reference-analytical-results.component';
import { ReferenceAuditComponent } from './components/staff/reference-audit.component';
import { ReferenceEditorComponent } from './components/staff/reference-editor.component';
import { ReferenceEditorAuthorComponent } from './components/staff/reference-editor-author.component';
import { ReferenceEditorBaseComponent } from './components/staff/reference-editor-base.component';
import { ReferenceEditorRelationsComponent } from './components/staff/reference-editor-relations.component';
import { ReferenceEditorSpecialComponent } from './components/staff/reference-editor-special.component';
import { ReferenceWorkflowStatsComponent } from './components/staff/reference-workflow-stats.component';
import { ReportingOrganizationEditorComponent } from './components/staff/reporting-organization-editor.component';
import { SpecialLinkEditorComponent } from './components/staff/special-link-editor.component';
import { StaffEditingComponent } from './components/staff/staff-editing.component';
import { SurveyReportedUseComponent } from './components/staff/survey-reported-use.component';
import { SurveyWhoUsedMostComponent } from './components/staff/survey-whousedmost.component';
import { TypePredictionClassEditorComponent } from './components/staff/type-prediction-class-editor.component';
import { TypePredictionClassesComponent } from './components/staff/type-prediction-classes.component';
import { TypePredictionEditorBaseComponent } from './components/staff/type-prediction-editor-base.component';
import { TypePredictionEditorComponent } from './components/staff/type-prediction-editor.component';
import { TypePredictionsComponent } from './components/staff/type-predictions.component';
import { TypeReferenceRelationEditorComponent } from './components/staff/type-reference-relation-editor.component';
import { TypeReferenceRelationSelectorComponent } from './components/staff/type-reference-relation-selector.component';
import { WhatsNewEditorComponent } from './components/staff/whats-new-editor.component';
import { WhatsNewSelectorComponent } from './components/staff/whats-new-selector.component';
import { WorkflowActivityReportComponent } from './components/staff/workflow-activity-report.component';
import { WorkflowMaterialHistoryComponent } from './components/staff/workflow-material-history.component';
import { WorkflowHistoryComponent } from './components/staff/workflow-history.component';
import { WorkflowReferenceAssignmentsComponent } from './components/staff/workflow-reference-assignments.component';
import { AddReferenceDocumentComponent } from './components/docmanagement/add-reference-document.component';
import { ArchivedDocumentSelectorComponent } from './components/docmanagement/archived-document-selector.component';
import { DocumentManagementComponent } from './components/docmanagement/document-management.component';
import { DocumentManagementReportsComponent } from './components/docmanagement/document-management-reports.component';
import { DocumentSelectorComponent } from './components/docmanagement/document-selector.component';
import { EditPDFPagesComponent } from './components/docmanagement/edit-pdf-pages.component';
import { PDFEditorComponent } from './components/docmanagement/pdf-editor.component';
import { QuickDocumentUploadComponent } from './components/docmanagement/quick-document-upload.component';
import { ReplaceReferenceDocumentComponent } from './components/docmanagement/replace-reference-document.component';
import { RIFMArchivedDocumentsComponent } from './components/docmanagement/rifm-archived-documents.component';
import { RIFMDocumentEditorComponent } from './components/docmanagement/rifm-document-editor.component';
import { RIFMDocumentsComponent } from './components/docmanagement/rifm-documents.component';
import { ShowAndDownloadPDFComponent } from './components/docmanagement/show-and-download-pdf.component';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor } from './services/auth-http-interceptor';
import { RouterModule } from '@angular/router';
import {DxButtonModule, DxDataGridModule, DxTooltipModule} from 'devextreme-angular';
import {PdfViewerModule} from '@syncfusion/ej2-angular-pdfviewer';
import { ColorPickerModule, UploaderModule  } from '@syncfusion/ej2-angular-inputs';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

import { DataServiceWebApiAdapter } from 'breeze-client/adapter-data-service-webapi';
import { DataServiceODataAdapter } from 'breeze-client/adapter-data-service-odata';
import { ModelLibraryBackingStoreAdapter } from 'breeze-client/adapter-model-library-backing-store';
import { UriBuilderJsonAdapter } from 'breeze-client/adapter-uri-builder-json';
import { UriBuilderODataAdapter } from 'breeze-client/adapter-uri-builder-odata';

import { AjaxHttpClientAdapter } from 'breeze-client/adapter-ajax-httpclient';

@NgModule({
    imports: [
        // BreezeBridgeHttpClientModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DxDataGridModule,
        DxTooltipModule,
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
        PdfViewerModule,
        ToolbarModule,
        DxButtonModule
    ],
    providers: [
        appRouterProviders,
        MaterialResolver,
        ReferenceResolver,
        SERVICE_PROVIDERS,
        customExceptionHandlerProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
    ],
    declarations: [
        AppComponent,
        ExceptionDialogComponent,
        LoginComponent,
        PrintToPdfDocComponent,
        ToolBar,
        YesNoComponent,

        DefaultTableComponent,
        EditorHeaderComponent,
        EditPropComponent,
        FormatUVFiles,
        ColumnSorterDirective,
        HtmlGreekDirective,
        ChemFormulaDirective,
        PageStatusComponent,
        PaginatePipe,
        PaginationControlsComponent,
        TabContainerVert,
        TabContainer,
        TabPane,
        DatePickerDirective,

        ExposureAdditionalMaterialsComponent,
        ExposureAdditionalMaterialEditorComponent,
        ExposureAdditionalMaterialSurveyMeasureEditorComponent,
        ExposureAdditionalMaterialSurveyMeasuresComponent,
        ExposureComponent,
        ExposureAdditionalMaterialSurveysComponent,
        ExposureAdditionalMaterialSurveyEditorComponent,
        ExposureSurveyEditorComponent,
        ExposureSurveyLastReportComponent,
        ExposureSurveysComponent,
        ExposureSurveyListsComponent,
        ExposureSurveyReportsComponent,
        MaterialExposureSurveyMeasuresComponent,
        MaterialExposureSurveyMeasureEditorComponent,
        MaterialExposureSurveyIsomerMeasuresComponent,
        MaterialExposureSurveysComponent,
        MaterialExposureSurveyEditorComponent,
        RecordExposureSurveyMeasuresComponent,

        ClusterHelpVideoComponent,
        ClusterPathFilterComponent,
        ClusterPathSelectorComponent,
        CytoscapeGraphComponent,
        HomeComponent,
        HomePageComponent,
        DatabaseSubscriptionComponent,
        EnvironmentalDataComponent,
        IFRAAttachmentsComponent,
        IFRASurveyUseComponent,
        IntroComponent,
        MaterialClustersComponent,
        MarvinSearchComponent,
        MaterialFFIDSComponent,
        MaterialGroupsComponent,
        ReportDatabaseChangesComponent,
        SafetyAssessmentsComponent,
        SearchAuthorsComponent,
        SearchResultsComponent,
        SearchSynonymsComponent,
        SystemRequirementsComponent,
        ToolsAndModelComponent,
        ToolsAndModelsComponent,
        ToolsAndModelsLicenseComponent,
        UserAcknowledgementComponent,
        WhatsNewComponent,
        WhatsNewSelectorComponent,

        AnalyticalResultComponent,
        AnalyticalResultsComponent,
        FlavorComponent,
        FlavorStatusesComponent,
        FlavorUsesComponent,
        FlavorUsesItemComponent,
        FlavorVolumesComponent,
        FragranceComponent,
        FragranceUseComponent,
        HouseStatusComponent,
        HouseStatusesComponent,
        HumanHealthComponent,
        MaterialAdditionalIdentifiersComponent,
        MaterialComponent,
        MaterialCompositionComponent,
        MaterialConsumptionsComponent,
        MaterialExposureComponent,
        MaterialHeaderComponent,
        MaterialPartOfCompoundsComponent,
        MaterialResultsComponent,
        MaterialSelectorComponent,
        MaterialSummaryComponent,
        NaturalsComponent,
        NaturalOccurrenceComponent,
        NaturalOccurrencesComponent,
        PredictionEnvironmentalComponent,
        PredictionHHAlertComponent,
        PredictionHHCramerComponent,
        PredictionHHTTCComponent,
        PredictionHumanHealthComponent,
        PredictionsComponent,
        PublicationsComponent,
        REACHRegistrationComponent,
        REACHRegistrationsComponent,
        RegulatoryStatusComponent,
        RegulatoryStatusesComponent,
        RelationsComponent,
        RelationsGroupComponent,
        RelationsGroupsComponent,
        RelationsMaterialComponent,
        RelationsMaterialsComponent,
        SafetyAssessmentComponent,
        StatusNtpComponent,
        StatusNtpsComponent,
        StatusRootComponent,
        StructureComponent,
        StructureIdentityComponent,
        SynonymComponent,
        SynonymsComponent,
        SynopsisComponent,
        SynopsisReportComponent,
        ToxDataOtherReferencesComponent,
        ToxicDataComponent,

        AuthorSelectorComponent,
        SafetyAssessmentCategoryEditorComponent,
        ReferenceComponent,
        ReferenceDataComponent,
        ReferenceDataReportComponent,
        ReferenceDetailsComponent,
        ReferenceHeaderComponent,
        ReferenceRelationsComponent,
        ReferenceResultsComponent,
        ReferenceSelectorComponent,

        AuthorEditorComponent,
        ComplianceSelectorComponent,
        ConfirmFinishOverrideComponent,
        ExpMaterialEditorComponent,
        ExpMaterialFromTemplateComponent,
        FSAGEditorComponent,
        FSAGSelectorComponent,
        JournalsComponent,
        JournalEditorComponent,
        JournalReserveEditorComponent,
        JournalReservesComponent,
        JournalNamesComponent,
        JournalReassignComponent,
        JournalSearchComponent,
        JournalSubscriptionEditorComponent,
        JournalSubscriptionsComponent,
        JournalSelectorComponent,
        MaterialEditorBaseComponent,
        MaterialEditorComponent,
        MaterialReportedUsesComponent,
        MaterialReportedUseEditorComponent,
        MaterialWorkflowHistoryComponent,
        PredictionCramerEditorComponent,
        PredictionsCramerEditorComponent,
        ProjectEditorComponent,
        ProjectSelectorComponent,
        ProtocolEditorComponent,
        ProtocolEditorBiodataComponent,
        ProtocolEditorSubStudiesComponent,
        ProtocolMaterialSelectorComponent,
        ReferenceAnalyticalResultsComponent,
        ReferenceAuditComponent,
        ReferenceEditorComponent,
        ReferenceEditorAuthorComponent,
        ReferenceEditorBaseComponent,
        ReferenceEditorRelationsComponent,
        ReferenceEditorSpecialComponent,
        ReferenceWorkflowStatsComponent,
        ReportingOrganizationEditorComponent,
        SpecialLinkEditorComponent,
        StaffEditingComponent,
        SurveyReportedUseComponent,
        SurveyWhoUsedMostComponent,
        TypePredictionClassEditorComponent,
        TypePredictionClassesComponent,
        TypePredictionEditorBaseComponent,
        TypePredictionEditorComponent,
        TypePredictionsComponent,
        TypeReferenceRelationEditorComponent,
        TypeReferenceRelationSelectorComponent,
        WhatsNewEditorComponent,
        WhatsNewSelectorComponent,
        WorkflowActivityReportComponent,
        WorkflowMaterialHistoryComponent,
        WorkflowHistoryComponent,
        WorkflowReferenceAssignmentsComponent,

        AddReferenceDocumentComponent,
        ArchivedDocumentSelectorComponent,
        DocumentManagementComponent,
        DocumentManagementReportsComponent,
        DocumentSelectorComponent,
        EditPDFPagesComponent,
        QuickDocumentUploadComponent,
        PDFEditorComponent,
        ReplaceReferenceDocumentComponent,
        RIFMArchivedDocumentsComponent,
        RIFMDocumentEditorComponent,
        RIFMDocumentsComponent,
        ShowAndDownloadPDFComponent,

    ],
    bootstrap: [AppComponent],

})
export class AppModule {
  constructor(http: HttpClient) {
    ModelLibraryBackingStoreAdapter.register();
    // UriBuilderJsonAdapter.register();
    UriBuilderODataAdapter.register();
    AjaxHttpClientAdapter.register(http);
    DataServiceWebApiAdapter.register();
    registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1iWX1fcnBVQ2hbV0A=;Mgo+DSMBMAY9C3t2VVhiQlFacl9JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1iWX1fcnBVQ2hVV0A=');
  }
}
