import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { StateMap, UnitOfWork, UserManager } from '../../services/common';
import { PageState } from '../../controls/common';

import { MATERIAL_ROUTES } from './material.routes';
import { ExpertPanelSummary, Material, MaterialExpertPanelSummary } from '../../entities/EntityModels';

@Component({
    selector: 'publications',
    templateUrl: './publications.html',
})
export class PublicationsComponent implements OnInit {

    _material: Material;
    _pageState = new PageState('Publications');
    _fmr: MaterialExpertPanelSummary;
    _summaries: ExpertPanelSummary[];
    _hasMonograph: boolean;


    constructor(public _uow: UnitOfWork, public _userManager: UserManager, public _stateMap: StateMap, public _location: Location) {

    }

    ngOnInit() {
        this._stateMap.currentRouteName = MATERIAL_ROUTES.Publications.name;
        this._material = this._stateMap.currentMaterial;
        this._material.principalSynonym().synonymWordOrWebVersion();

        this._pageState.isLoading = true;
        this._uow.materialExpertPanelSummaryRepository.whereWithParams({materialId: this._material.materialId}).then(r => {
            this._fmr = _.find(r, m => m.review != null);
            const summaries = r.map(item => item.expertPanelSummary);
            this._summaries = summaries.filter(s => s.narrativeLink != null && s.narrativeLink.trim() != '');
            this._hasMonograph = (this._material.monographReference != null) && (this._material.monographReference.trim() != '' && this._material.monographNumber != null);
            this._pageState.isLoaded = (summaries.length > 0) || (this._fmr != null) || this._hasMonograph;
        });

    }

    linkToSummary(summary: ExpertPanelSummary) {
        const url = this._userManager.getExpertPanelSummaryUrl(summary.narrativeLink);
        window.open(url, '_self');
        return false;
    }

    linkToFmr() {
        const path = `Expert Panel Summaries\\${this._fmr.review}`;
        const url = this._userManager.getFileServiceFilePathUrl(path);
        window.open(url, '_self');
        return false;
    }

    linkToMonograph() {
        const url = this._userManager.getMonographUrl(this._material.monographNumber);
        window.open(url, '_self');
        return false;
    }


}
