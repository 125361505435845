import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { IRouteDefinition, SearchService, StateMap, UnitOfWork } from '../../services/common';

import { Material } from '../../entities/Material';
import { MaterialResolver } from './material.resolver';
import { MATERIAL_ROUTES } from './material.routes';


@Component({
    selector: 'material',
    templateUrl: './material.html',
})
export class MaterialComponent implements OnInit, OnDestroy {
    material: Material;
    currentMaterialId: number;
    additionalIdsVisibility = 'hidden';
    _sub: any;
    _materialNotFound = false;
    _routes = <IRouteDefinition[]>_.values(MATERIAL_ROUTES);

    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, public _searchService: SearchService,
                private _materialResolver: MaterialResolver, public _route: ActivatedRoute, public _location: Location) {
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    canShow(routeDef: IRouteDefinition) {

        this.additionalIdsVisibility = 'hidden';

        if (this._stateMap.currentUser.isStaff) {
            // Exception to prevent consultant staff from seeing otherwise staff viewable content.
            if (routeDef.rolesExcluded) {
                const roles = routeDef.rolesExcluded.split(',');
                if (roles != null && roles.length > 0) {
                    return (!this.userRoleFound(roles));
                }
            }
            return true;
        }

        if (typeof routeDef.staffOnly === 'undefined') {    // staffOnly only defined if true
            return true;
        }

        // Exception to allow a role to view staff only content
        if (routeDef.rolesPermitted) {
            const roles = routeDef.rolesPermitted.split(',');
            if (roles != null && roles.length > 0) {
                return (this.userRoleFound(roles));
            }
        }


        return false;
    }

    userRoleFound(roles: string[]): boolean {
        for (let idx = 0; idx < roles.length; idx++) {
            if (this._stateMap.currentUser.isInRole(roles[idx])) {
                return true;
            }
        }

        return false;
    }

    ngOnInit() {
        this._sub = this._route.params.subscribe(params => {
            // this is needed to get the right header to display
            const materialId = +params['id'];
            this._materialResolver.fetch(materialId).then(m => {
                this.material = m;
                this.currentMaterialId = (this.material) ? this.material.materialId : null;
                this._materialNotFound = !this.material;
            });
        });
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    canDeactivate() {
        if (this._uow.hasChanges()) {
            return this._stateMap.yesNoComponent.showModal(
                'Changes will be lost', 'Do you want to continue?').then(ok => {
                if (ok) {
                    this._uow.rollback();
                }
                return ok;
            });
        } else {
            return Promise.resolve(true);
        }
    }

    isActiveTab(r: IRouteDefinition) {
        const currentRouteName = this._stateMap.currentRouteName || this._routes[0].name;
        return r.name == currentRouteName;
    }


}
