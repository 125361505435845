import {Location} from '@angular/common';
import {Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from '../../../environments/environment';

import {IRouteDefinition, ErrorLogger, StateMap, UnitOfWork, UserManager} from '../../services/common';
import {IEditHost, UtilFns} from '../../utils/common';
import {HttpClient, HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';

import {Reference, RIFMDocument} from '../../entities/EntityModels';
import {DocumentSelectorComponent} from '../docmanagement/document-selector.component';
import {PDFEditorComponent} from '../docmanagement/pdf-editor.component';
import { ConfirmFinishOverrideComponent } from '../staff/confirm-finish-override.component';
import {Observable} from 'rxjs';
import {DOCUMENTMANAGEMENT_ROUTES} from '../docmanagement/document-management.routes';

export class ReferenceDocumentEditData {
    inPDFSubEditor: boolean;
    adding: boolean;
    editingPDF: boolean;
    editingPDFPages: boolean;
    showPDFToolbar: boolean;
    replacing: boolean;
    entity: RIFMDocument;
    rIFMDocumentId: number;
    referenceId: number;
}

@Component({
    selector: 'rifm-documents',
    templateUrl: '../docmanagement/rifm-documents.html',
})

export class RIFMDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DocumentSelectorComponent) _documentSelector: DocumentSelectorComponent;
    @ViewChild(PDFEditorComponent, { static: true }) _pdfEditorComponent: PDFEditorComponent;
    @ViewChild(ConfirmFinishOverrideComponent, { static: true }) _confirmFinishOverrideComponent: ConfirmFinishOverrideComponent;

    public _data: ReferenceDocumentEditData = new ReferenceDocumentEditData();

    public _isLoading = false;
    public _isSavingPDFFile = false;
    public _documentFileName: string;
    public _pdfProcessingMessage = '';
    public _selectedRIFMDocument: RIFMDocument;

    _rifmDocumentSelectorNextAction = 'Hide Filters';
    _showRIFMDocumentSelector = true;

    public _editorWidth        = '1200px';
    public _selectorWidth      = '1200px';
    public _selectorDisplay    = 'inline';

    public _validationMessage: string;

    public _pdfEditorLastRequest = '';
    public _pdfEditorMessage = '';

    public _showSelectionMessage = false;

    public _workflowReference: Reference;

    public _paramSearchTerm = '';

    // tslint:disable-next-line:max-line-length
    constructor(public _uow: UnitOfWork, public _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                private _httpClient: HttpClient, private _route: ActivatedRoute, public _location: Location, public _errorLogger: ErrorLogger) {
    }

    ngOnInit() {

        this._stateMap.currentRouteName = DOCUMENTMANAGEMENT_ROUTES.RIFMDocuments.name;

        this._data.inPDFSubEditor  = false;

        if (!this.isValidString(this._stateMap.documentManagementArchiveUrl) || !this.isValidString(this._stateMap.documentManagementUrl)) {
            this.getDocumentManagementUrl().then(url => {
                this._stateMap.documentManagementArchiveUrl  = url + '/RIFMDocumentArchive';
                this._stateMap.documentManagementUrl         = url + '/RIFMDocument';
            });
        }

        // _paramSearchTerm is an input parameter to the document-selector-component to pre-populate the selection and display of the reference document
        this._paramSearchTerm = (this._stateMap.documentManagementReferenceId != null) ? this._stateMap.documentManagementReferenceId + '' : '';
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this._pdfEditorComponent != null) {
            this._pdfEditorComponent.destroyData();
        }

        // remove application cache
        this.clearPDFViewerCache();
    }

    public clearPDFViewerCache() {
        // remove application cache
        for (const key in sessionStorage) {
            if (key.indexOf('Sync_PdfViewer') !== -1) {
                sessionStorage.removeItem(key);
            }
        }
    }

    // ************************************************************************
    // load data
    // ************************************************************************
    fetchRIFMDocumentThenEditPDF(rifmDocumentId: number) {
        this._selectedRIFMDocument = this._data.entity = null;

        this._uow.fetch('DocumentManagement/RIFMDocumentById', {rifmDocumentId: rifmDocumentId}).then(rd => {
            this._selectedRIFMDocument = (rd && rd.length) ? rd[0] : null;
            if (this._selectedRIFMDocument) {

                this._data.rIFMDocumentId = this._selectedRIFMDocument.rIFMDocumentId;
                this._data.entity = this._selectedRIFMDocument;

                const data              = this._data;
                data.adding             = false;
                data.editingPDF         = true;
                data.replacing          = false;
                data.inPDFSubEditor     = true;

                this.showRIFMDocumentSelector = false;

                if (this._selectedRIFMDocument.fileName) {
                    this._pdfEditorComponent.loadPDF(this._selectedRIFMDocument.fileName);
                    this.documentFileName = this._selectedRIFMDocument.fileName;
                    this._pdfEditorComponent.setEditMode();
                }
            }
        });
    }

    fetchRIFMDocumentThenEditForEditPDFPages(rifmDocumentId: number) {
        this._selectedRIFMDocument = this._data.entity = null;

        this._uow.fetch('DocumentManagement/RIFMDocumentById', {rifmDocumentId: rifmDocumentId}).then(rd => {
            this._selectedRIFMDocument = (rd && rd.length) ? rd[0] : null;
            if (this._selectedRIFMDocument) {

                this._data.rIFMDocumentId   = this._selectedRIFMDocument.rIFMDocumentId;
                this._data.entity           = this._selectedRIFMDocument;

                const data              = this._data;
                data.adding             = false;
                data.editingPDF         = false;
                data.editingPDFPages    = true;
                data.replacing          = false;
                data.inPDFSubEditor     = true;

                this.showRIFMDocumentSelector = false;
            }
        });
    }

    // ************************************************************************
    // event handlers
    // ************************************************************************
    documentSelectorEventHandler(event: string) {
        // if the component is in edit mode, don't respond to selection events and notifiy the user

        if (this._data.inPDFSubEditor) {
            this.showSelectionMessage = true;
        }

        switch (event) {
            case 'edit': {
                this._validationMessage = '';

                // The _paramSearchTerm is an input parameter to the document-selector component.
                // When the user exits the Edit mode, the selector will reload the document referenced in the _paramSearchTerm
                this._paramSearchTerm = this._documentSelector.selectedReferenceId + '';

                this.fetchRIFMDocumentThenEditPDF(this._documentSelector.selectedRIFMDocumentId);
                break;
            }
            case 'editPDFPages': {
                this._validationMessage = '';
                this.fetchRIFMDocumentThenEditForEditPDFPages(this._documentSelector.selectedRIFMDocumentId);
                break;
            }
            case 'export': {
                this._validationMessage = '';
                this.exportPDFFile(this._documentSelector.selectedRIFMDocumentId, this._documentSelector.selectedReferenceId);
                break;
            }
            case 'view': {
                this._pdfEditorLastRequest = 'Loading PDF file';

                this._validationMessage = '';
                this.loadPDFFileData(this._documentSelector.selectedFileName);
                this.documentFileName = this._documentSelector.selectedFileName;
                break;
            }
            case 'replace': {
                this._validationMessage = '';

                this.onReplaceRIFMDocumentFile();

                break;
            }
            case 'remove': {
                this._validationMessage = '';

                // don't display a file that is going to be removed
                if (this._pdfEditorComponent.downloadFileName == this._documentSelector.selectedFileName) {
                    this._pdfEditorComponent.unloadPDF();
                }

                this.removeReferenceDocument(this._documentSelector.selectedRIFMDocumentId, this._documentSelector.selectedReferenceId);
                break;
            }
            case 'deletefile': {
                this._validationMessage = '';
                this.deleteFile(this._documentSelector.selectedRIFMDocumentId, this._documentSelector.selectedReferenceId);
                break;
            }
            default: {
                break;
            }
        }
    }

    pdfEditorEventHandler(event: string) {
        switch (event) {
            case 'documentchanged': {
                break;
            }
            case 'documentloaded': {
                break;
            }
            case 'downloadend': {
                this._pdfEditorMessage  = this._pdfEditorLastRequest + ' Result: ' + 'Success.';
                this._isSavingPDFFile   = false;
                break;
            }
            case 'loadfailederror': {
                this._pdfEditorMessage = this._pdfEditorLastRequest + ' Result: ' + 'Unsuccessful, please contact support.';
                break;
            }
            case 'success': {
                // this._pdfEditorMessage = this._pdfEditorLastRequest + ' Result: ' + 'Success.';
                break;
            }
            case 'error': {
                this._pdfEditorMessage = this._pdfEditorLastRequest + ' Result: ' + 'Unsuccessful, please contact support.';
                this._isSavingPDFFile = false;
                break;
            }
            default: {
                this._pdfEditorMessage = '';
                break;
            }
        }

        this._isLoading             = false;
        this._pdfProcessingMessage  = '';
    }

    // ************************************************************************
    // document management methods not managed by the pdf editor
    // ************************************************************************
    public removeReferenceDocument(rifmDocumentId: number, referenceId: number): boolean {

        const messages: string[] = [];
        this._confirmFinishOverrideComponent.showModal('Confirm Removal of a Reference Document',
            'This action will archive the assigned file and remove the meta data record. Do you want to proceed?', messages).then(ok => {
            if (ok) {
                return this.removeRIFMDocumentApiCall(rifmDocumentId, referenceId);
            }
        });

        return false;
    }

    public deleteFile(rifmDocumentId: number, referenceId: number): boolean {

       this.deleteAndArchiveRIFMDocumentFile(rifmDocumentId, referenceId);
       return true;

        // const messages: string[] = [];
        // this._confirmFinishOverrideComponent.showModal('Confirm Deletion of the File',
        //     'This action will archive the assigned file and remove the file name from the meta data record. Do you want to proceed?', messages).then(ok => {
        //     if (ok) {
        //         return this.deleteAndArchiveRIFMDocumentFile(rifmDocumentId, referenceId);
        //     }
        // });
        //
        // return false;
    }

    // ************************************************************************
    canDeactivate() {
        return true;
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get documentFileName() {
        return this._documentFileName;
    }

    set documentFileName(value) {
        this._documentFileName = value;
    }

    get pdfEditorDisplayElement(): string {
        return (!this._data.adding && !this._data.replacing && !this._data.editingPDFPages) ? 'inline' : 'none';
    }

    // this property was added to hide, not destroy, the pdf viewer due to lags in load time
    get pdfEditorDisplayElementHeight(): string {
        return (!this._data.adding && !this._data.replacing && !this._data.editingPDFPages) ? 'auto' : '10px';
    }

    get pdfHasChanges(): boolean {
        return (this._pdfEditorComponent != null) ? this._pdfEditorComponent.hasChanges : false;
    }

    // label to show or hide the document selector component
    get rifmDocumentSelectorNextAction() {
        return this._rifmDocumentSelectorNextAction;
    }

    set rifmDocumentSelectorNextAction(value) {
        this._rifmDocumentSelectorNextAction = value;
    }

    get showRIFMDocumentSelector() {
        return this._showRIFMDocumentSelector;
    }

    set showRIFMDocumentSelector(value) {
        this._showRIFMDocumentSelector = value;
    }

    get showSelectionMessage() {
        return this._showSelectionMessage;
    }

    set showSelectionMessage(value) {
        this._showSelectionMessage = value;
    }

    get utilFns() {
        return UtilFns;
    }

    // ************************************************************************
    // interactions
    // ************************************************************************
    onSetSelectorVisibility() {
        if (this.rifmDocumentSelectorNextAction == 'Hide Filters') {
            this.rifmDocumentSelectorNextAction = 'Show Filters';
            this._selectorDisplay = 'none';
        } else {
            this.rifmDocumentSelectorNextAction = 'Hide Filters';
            this._selectorDisplay = 'inline';
        }
    }

    // ************************************************************************
    // PDF Editing
    // ************************************************************************
    canCancel() {
        return (this._pdfEditorComponent != null && this._pdfEditorComponent.hasChanges);
    }

    canSave() {
        return (this._pdfEditorComponent != null && this._pdfEditorComponent.hasChanges);
    }

    canShowBack() {
        return (!this._isSavingPDFFile && this._pdfEditorComponent != null && !this._pdfEditorComponent.hasChanges);
    }

    onAdd() {
        this._data.adding               = true;
        this.showRIFMDocumentSelector   = false;
    }

    addCompleted(referenceId: number) {
        this._data.adding               = false;
        this.showRIFMDocumentSelector   = true;

        if (referenceId != -1) {
            this._paramSearchTerm = referenceId + '';
        }
    }

    editPagesCompleted(msg: string) {
        this._data.editingPDFPages      = false;
        this._data.inPDFSubEditor       = false;

        this.showRIFMDocumentSelector   = true;
        this._validationMessage         = msg;

        // clear the cache so the updated pdf file will be displayed instead of the cached one
        setTimeout(() => {
            this.clearPDFViewerCache();
        }, 500);

        if (msg == 'viewPDF') {
            this._paramSearchTerm = this._data.entity.referenceId + '';
        } else {
            this._paramSearchTerm = '';
        }
    }

    replaceCompleted(msg: string) {
        this._data.replacing            = false;
        this.showRIFMDocumentSelector   = true;

        this._validationMessage = msg;
    }

    onBack() {
        this.resetDisplay();
    }

    resetDisplay() {
        this._pdfEditorMessage = '';
        this._validationMessage = '';

        this._data.adding           = false;
        this._data.editingPDF       = false;
        this._data.replacing        = false;
        this._data.inPDFSubEditor   = false;

        this._pdfEditorComponent.setViewMode();

        this.showSelectionMessage       = false;
        this.showRIFMDocumentSelector   = true;
    }

    onCancel() {
        this.resetDisplay();
    }

    onSave() {
        this._isSavingPDFFile = true;
        this._pdfEditorComponent.savePDF();
        this._pdfEditorLastRequest = 'Save PDF File Changes';
    }

    onReplaceRIFMDocumentFile() {
        this._data.replacing        = true; // displays the replace component

        this._data.rIFMDocumentId   = this._documentSelector.selectedRIFMDocumentId;
        this._data.referenceId      = this._documentSelector.selectedReferenceId;

        this.showRIFMDocumentSelector   = false;
    }

    // ************************************************************************
    // PDF Component Interaction
    // ************************************************************************
    public loadPDFFileData(fileName: string) {
        this._isLoading = true;
        this._pdfProcessingMessage = 'Loading the PDF File...';
        this._validationMessage = '';

        this._pdfEditorComponent.canShowPDF = true;
        this._pdfEditorComponent.loadPDF(fileName);
        this._pdfEditorComponent.setViewMode();
    }

    // ************************************************************************
    // Web Api Urls
    // ************************************************************************
    getArchiveFileURL(rifmDocumentId: number, referenceId: number): Observable<any> {
        const url = this._stateMap.documentManagementArchiveUrl + '/ArchiveFile?rifmDocumentId=' + rifmDocumentId + '&referenceId=' + referenceId;
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this._httpClient.post<any>(url, { });
    }

    getExportFileURL(rifmDocumentId: number): Observable<any> {
        const url = this._stateMap.documentManagementUrl + '/GetReferenceDocumentPDF?rifmDocumentId=' + rifmDocumentId;
        return this._httpClient.get<any>(url, { responseType: 'blob' as 'json'});
    }

    getRemoveDocumentURL(rifmDocumentId: number, referenceId: number): Observable<any> {
        const url = this._stateMap.documentManagementArchiveUrl + '/Remove?rifmDocumentId=' + rifmDocumentId + '&referenceId=' + referenceId;
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        return this._httpClient.post<any>(url, { });
    }

    // ************************************************************************
    // http requests to rifm document file service
    // ************************************************************************
    public deleteAndArchiveRIFMDocumentFile(rifmDocumentId: number, referenceId: number) {
        this._isLoading = true;
        this._pdfProcessingMessage = 'Archiving and Deleting the PDF file...';
        this._validationMessage = '';
        this.getArchiveFileURL(rifmDocumentId, referenceId)
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to remove the file: ' + data.ExceptionMessage;
                        return;
                    }

                    // refresh the list of archived files in the selector since one record has been removed when the file was restored.
                    this._documentSelector.refreshWithRecentlyModified();

                    this._pdfProcessingMessage  = '';
                        this._validationMessage = 'Document file deleted and archived successfully.';
                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage     = error.message;
                    this._isLoading             = false;
                    this._pdfProcessingMessage  = '';
                },
                () => {
                    console.error('Request completed');
                    this._isLoading             = false;
                    this._pdfProcessingMessage  = '';
                });
    }

    public exportPDFFile(rifmDocumentId: number, referenceId: number) {
        this._isLoading = true;
        this._pdfProcessingMessage = 'Downloading the PDF file...';
        this._validationMessage = '';
        this.getExportFileURL(rifmDocumentId)
            .subscribe(
                (response: Blob) => {
                    const data = response;

                    const file = new Blob([data], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);

                    // window.open(fileURL);
                    const a         = document.createElement('a');
                    a.href        = fileURL;
                    a.target      = '_blank';
                    a.download    = referenceId + '.pdf';
                    document.body.appendChild(a);
                    a.click();

                    this._pdfProcessingMessage  = '';
                    this._validationMessage     = 'File download successful.';
                },
                (error: any) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                    this._pdfProcessingMessage  = '';
                },
                () => {
                    this._isLoading = false;
                    this._pdfProcessingMessage  = '';
                });
    }

    public removeRIFMDocumentApiCall(rifmDocumentId: number, referenceId: number) {
        this._isLoading = true;
        this._validationMessage = '';
        this.getRemoveDocumentURL(rifmDocumentId, referenceId)
            .subscribe(
                (response) => {
                    const data = response;

                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to remove the file: ' + data.ExceptionMessage;
                        return;
                    }

                    // refresh the list of archived files in the selector since one record has been removed when the file was restored.
                    this._documentSelector.refreshWithRecentlyModified();

                    this._validationMessage = 'Reference document meta data and file archived and removed successfully.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                },
                () => {
                    this._isLoading = false;
                });
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    public getDocumentManagementUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public isValidString(testString: string): boolean {
        return (testString && testString.length > 0);
    }
}

