import { Location } from '@angular/common';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {UnitOfWork, StateMap, UserManager, ErrorLogger, ReferenceState, WorkflowEntityState} from '../../services/common';
import { UtilFns, IEditHost, EditManager } from '../../utils/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

import { Reference, RIFMDocument, RIFMDocumentFolder, RIFMDocumentArchive, TypeRIFMDocument } from '../../entities/EntityModels';
import {ArchivedDocumentSelectorComponent} from './archived-document-selector.component';
import {Observable} from 'rxjs';
import {PDFEditorComponent} from './pdf-editor.component';

import { DOCUMENTMANAGEMENT_ROUTES } from './document-management.routes';

@Component({
    selector: 'rifm-archived-documents',
    templateUrl: './rifm-archived-documents.html',
})

export class RIFMArchivedDocumentsComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(ArchivedDocumentSelectorComponent) _archivedDocumentSelector: ArchivedDocumentSelectorComponent;
    @ViewChild(PDFEditorComponent, { static: true }) _pdfEditorComponent: PDFEditorComponent;

    public _adding = false;

    public _isLoading = false;
    public _isRestoring = false;
    public _selectedRIFMDocumentArchive: RIFMDocumentArchive;

    _rifmDocumentSelectorNextAction = 'Hide Filters';
    _showRIFMDocumentSelector = true;

    public _editorWidth        = '800px';
    public _selectorWidth      = '600px';
    public _selectorDisplay    = 'inline';
    public _validationMessage: string;

    public _workflowReference: Reference;

    constructor(private _uow: UnitOfWork, private _stateMap: StateMap, private _userManager: UserManager, private _router: Router,
                private _httpClient: HttpClient, private _route: ActivatedRoute, public _location: Location,
                private _referenceState: ReferenceState) {
    }

    ngOnInit() {

        this._stateMap.currentRouteName = DOCUMENTMANAGEMENT_ROUTES.RIFMArchivedDocuments.name;

        if (!this.isValidString(this._stateMap.documentManagementArchiveUrl)) {
            this.getDocumentManagementArchiveUrl().then(url => {
                this._stateMap.documentManagementArchiveUrl = url + '/RIFMDocumentArchive';
                this._pdfEditorComponent.serviceUrl         = this._stateMap.documentManagementArchiveUrl;
            });
        } else {
            this._pdfEditorComponent.serviceUrl = this._stateMap.documentManagementArchiveUrl;
        }
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {

        if (this._pdfEditorComponent != null) {
            this._pdfEditorComponent.destroyData();
        }

        // remove application cache
        this.clearPDFViewerCache();
    }

    public clearPDFViewerCache() {
        // remove application cache
        for (const key in sessionStorage) {
            if (key.indexOf('Sync_PdfViewer') !== -1) {
                sessionStorage.removeItem(key);
            }
        }
    }

    // ************************************************************************
    // load data
    // ************************************************************************
    // fetchThenEditReference(referenceId: number) {
    //     return this._uow.fetch('References/GetForWorkflow', { referenceId: referenceId }).then(rd => {
    //         const ref = (rd && rd.length) ? rd[0] : null;
    //         if (ref) {
    //             this._workflowReference = ref;
    //         }
    //     });
    // }

    archivedDocumentSelectorEventHandler(event: string) {
        switch (event) {
            case 'restore': {
                this._validationMessage = '';
                this.restoreRIFMDocumentFile(this._archivedDocumentSelector.selectedRIFMDocumentArchive.rIFMDocumentArchiveId, this._archivedDocumentSelector.selectedRIFMDocumentArchive.referenceId);
                break;
            }
            case 'view': {
                this._validationMessage = '';
                this.loadPDFFileDataAPI(this._archivedDocumentSelector.selectedFileName);
                break;
            }
            default: {
                break;
            }
        }
    }

    // ************************************************************************
    canDeactivate() {
        return (!this._isRestoring);
    }

    isStaff() {
        return this._stateMap.currentUser.isStaff;
    }

    get rifmDocumentSelectorNextAction() {
        return this._rifmDocumentSelectorNextAction;
    }

    set rifmDocumentSelectorNextAction(value) {
        this._rifmDocumentSelectorNextAction = value;
    }

    get showRIFMDocumentSelector() {
        return this._showRIFMDocumentSelector;
    }

    set showRIFMDocumentSelector(value) {
        this._showRIFMDocumentSelector = value;
    }

    get utilFns() {
        return UtilFns;
    }

    // ************************************************************************
    // interactions
    // ************************************************************************
    onSetSelectorVisibility() {
        if (this.rifmDocumentSelectorNextAction == 'Hide Filters') {
            this.rifmDocumentSelectorNextAction = 'Show Filters';
            this._selectorDisplay               = 'none';
            this._editorWidth                   = '1200px';
        } else  {
            this.rifmDocumentSelectorNextAction = 'Hide Filters';
            this._selectorDisplay               = 'inline';
            this._editorWidth                   = '800px';
        }
    }

    // ************************************************************************
    // PDF Viewer
    // ************************************************************************
    public loadPDFFileDataAPI(fileName: string) {
        this._isLoading = true;
        this._validationMessage = '';
        this._pdfEditorComponent.loadPDF(fileName);
        this._pdfEditorComponent.setViewMode();
        this._isLoading = false;
    }

    // ************************************************************************
    // Web Api Urls
    // ************************************************************************
    getRestoreFileURL(rifmDocumentArchiveId: number, referenceId: number): Observable<any> {
        const url = this._stateMap.documentManagementArchiveUrl + '/Restore?rifmDocumentArchiveId=' + rifmDocumentArchiveId + '&referenceId=' + referenceId;
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');

        // const token = this._stateMap.currentUser.authenticationToken;
        // headers.append('Authorization', token);

        return this._httpClient.post<any>(url, { });
    }

    // ************************************************************************
    // HttpClient calls
    // ************************************************************************
    public restoreRIFMDocumentFile(rifmDocumentArchiveId: number, referenceId: number) {
        this._isLoading = true;
        this._isRestoring = true;
        this._validationMessage = '';
        this.getRestoreFileURL(rifmDocumentArchiveId, referenceId)
            .subscribe(
                (response) => {
                    const data = response;
                    if (data.Success == false) {
                        this._validationMessage = 'Error occurred, unable to restore the file.' + data.ExceptionMessage;
                        return;
                    }

                    // refresh the list of archived files in the selector since one record has been removed when the file was restored.
                    this._archivedDocumentSelector.refreshWithRecentlyArchived();
                    this._isRestoring = false;

                    this._validationMessage = 'RIFM Document restored successfully.';

                },
                (error) => {
                    console.error('Request failed with error: ' + error.message);
                    this._validationMessage = error.message;
                    this._isLoading = false;
                    this._isRestoring = false;
                },
                () => {
                    console.error('Request completed');
                    this._isLoading = false;
                    this._isRestoring = false;
                });
    }

    // ************************************************************************
    // document management web api url
    // ************************************************************************
    public getDocumentManagementArchiveUrl(): Promise<any> {
        return this._uow.fetch('DocumentManagement/DocumentManagementURL', {}).then(dm => {
            return dm[0];
        });
    }

    public isValidString(testString: string): boolean {
        return (testString && testString.length > 0);
    }
}
