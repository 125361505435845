import { EntityType, EntityQuery } from 'breeze-client';
import {IRepository, Repository} from './repository';
import {Reference} from '../entities/EntityModels';
import {EntityManagerProvider} from './entity-manager-provider';



export class ReferenceRepository extends Repository<Reference> {
    constructor(
        protected emProvider: EntityManagerProvider,
        protected entityTypeName: string,
        protected expand: string,
        protected resourceName: string) {
        super(emProvider, entityTypeName, expand, resourceName); 
    }
        
    populateBiologicalData(reference: Reference) {
        var et = <EntityType> this.manager.metadataStore.getEntityType('BiologicalData');
        et.setProperties( {
            defaultResourceName: 'BiologicalDatas/get'
        });
        var navQuery = <EntityQuery> (<any> EntityQuery).fromEntityNavigation(reference, 'biologicalDatas');
        // No longer need to expand 'type' tables since they are already down. 
        // navQuery =navQuery.expand(['typeRoute', 'typeStudy', 'typeSpecies'
        //     , 'experimentalMaterials.material.synonyms.synonymWebVersion'
        //     , 'experimentalMaterials.experimentalResults.typeUnit'
        //     , 'experimentalMaterials.experimentalResults.experimentalToxicEffects.typeToxicEffect'
        //     ] );
        navQuery =navQuery.expand([
            'experimentalMaterials.material.synonyms',
            'experimentalMaterials.experimentalResults.experimentalToxicEffects',
            'bioDataStudySubTypes'
        ]);
            
        return this.executeQuery(navQuery);
        
        // similar to ( but with expands)
        // return reference.entityAspect.loadNavigationProperty("biologicalDatas");
    }

    populateToxData(reference: Reference) {
        var et = <EntityType>this.manager.metadataStore.getEntityType('BiologicalData');
        et.setProperties({
            defaultResourceName: 'BiologicalDatas/get'
        });
        var navQuery = <EntityQuery>(<any>EntityQuery).fromEntityNavigation(reference, 'biologicalDatas');
        navQuery = navQuery.expand([
            'experimentalMaterials.experimentalResults'
        ]);

        return this.executeQuery(navQuery);
    }

    populateAnalyticalResultData(reference: Reference) {
        var et = <EntityType>this.manager.metadataStore.getEntityType('AnalyticalResult');
        et.setProperties({
            defaultResourceName: 'AnalyticalResult/get'
        });
        var navQuery = <EntityQuery>(<any>EntityQuery).fromEntityNavigation(reference, 'analyticalResults');

        return this.executeQuery(navQuery);
    }

    populateSpecialLinkData(reference: Reference) {
        var et = <EntityType>this.manager.metadataStore.getEntityType('SpecialLink');
        et.setProperties({
            defaultResourceName: 'SpecialLink/get'
        });
        var navQuery = <EntityQuery>(<any>EntityQuery).fromEntityNavigation(reference, 'specialLinks');

        return this.executeQuery(navQuery);
    }

    populateWorkflowData(reference: Reference) {
        var et = <EntityType>this.manager.metadataStore.getEntityType('WorkflowRecordedAction');
        et.setProperties({
            defaultResourceName: 'WorkflowRecordedAction/get'
        });
        var navQuery = <EntityQuery>(<any>EntityQuery).fromEntityNavigation(reference, 'workflowRecordedActions');

        return this.executeQuery(navQuery);
    }
}